import { AxiosError } from 'axios'
import clsx from 'clsx'
import { FC, useRef, useState } from 'react'

import { useDeposit } from '../../deposit-list/api'
import { IDepositPayment } from '../../deposit-list/ui/deposit-list.interface'

import styles from './DepositModal.module.scss'
import { messages } from '@/i18n/messages'
import { useErrorAlert } from '@/shared/hooks/useErrorAlert'
import { useIsMobile } from '@/shared/hooks/useIsMobile'
import { useAppSelector } from '@/shared/model'
import { IGetDeposit } from '@/shared/types/user.types'
import { Button } from '@/shared/ui/form'
import Modal from '@/shared/ui/modal/Modal'
import { TModalModifers } from '@/shared/ui/modal/modal.type'

interface IProps {
	isOpen: boolean
	onCLose: () => void
	onClick?: () => void
	data?: IDepositPayment
}

type DataPrice = {
	title: string
	count: number
}

// const dataPrices: DataPrice[] = [
// 	{ title: '20 000 betcoins', count: 200 },
// 	{ title: '50 000 betcoins', count: 500 },
// 	{ title: '70 000 betcoins', count: 700 },
// 	{ title: '100 000 betcoins', count: 1000 }
// ]

const dataPrices = {
	WEIXIN: [
		{ title: '20 000 betcoins', count: 200 },
		{ title: '50 000 betcoins', count: 500 },
		{ title: '80 000 betcoins', count: 800 },
		{ title: '100 000 betcoins', count: 1000 }
	],
	ALIPAY: [
		{ title: '20 000 betcoins', count: 200 },
		{ title: '50 000 betcoins', count: 500 },
		{ title: '70 000 betcoins', count: 700 },
		{ title: '100 000 betcoins', count: 1000 }
	],
	WEIXIN_H5: [
		{ title: '20 000 betcoins', count: 200 },
		{ title: '50 000 betcoins', count: 500 },
		{ title: '80 000 betcoins', count: 800 },
		{ title: '100 000 betcoins', count: 1000 }
	],
	ALIPAY_H5: [
		{ title: '20 000 betcoins', count: 200 },
		{ title: '50 000 betcoins', count: 500 },
		{ title: '70 000 betcoins', count: 700 },
		{ title: '100 000 betcoins', count: 1000 }
	],
	TAOBAO: [
		{ title: '2 000 betcoins', count: 10 },
		{ title: '5 000 betcoins', count: 50 },
		{ title: '10 000 betcoins', count: 100 },
		{ title: '20 000 betcoins', count: 200 }
	]
}

export const ModalDeposit: FC<IProps> = ({ isOpen, onCLose, data }) => {
	const { isMobile } = useIsMobile(1180)
	const { locale } = useAppSelector(state => state.defaultReducer)
	const { addErrorAlert } = useErrorAlert()
	const [selected, setSelected] = useState<DataPrice | null>()
	const [urlPayment, setUrlPayment] = useState('')
	const ref = useRef<HTMLButtonElement>(null)
	const [isSuccess, setIsSuccess] = useState(false)
	const { mutate, isLoading } = useDeposit()

	const onHandlerClick = () => {
		const dataSync = {
			count: selected?.count,
			method: data?.method
		} as IGetDeposit

		mutate(dataSync, {
			onSuccess: ({ data }) => {
				setUrlPayment(data)
				setTimeout(() => {
					ref.current?.click()
				}, 100)
			},
			onError: e => {
				const error = e as AxiosError<any, any>
				addErrorAlert(error)
			}
		})
	}
	const onCLoseModal = () => {
		onCLose()
		setIsSuccess(false)
		setSelected(null)
	}

	const modifers = [
		'simple',
		'tiny',
		'light',
		'blur',
		'absolute',
		'deposit',
		...(isMobile ? ['small'] : [])
	] as TModalModifers

	return (
		<Modal
			isOpen={isOpen}
			onClose={onCLoseModal}
			disabledPortal
			modifers={modifers}
		>
			<div className={styles.wrapper}>
				<form style={{ display: 'none' }} action={urlPayment} method='POST'>
					<button ref={ref} type='submit' />
				</form>
				{isSuccess ? (
					<>
						<h3 className={styles.title}>successfully</h3>
						<p className={styles.info}>
							Your account has been replenished with {selected?.title}
						</p>
						<Button
							className={styles.button}
							onClick={onCLoseModal}
							modifiers={['gold', 'small']}
							title={'Ok'}
						/>
					</>
				) : (
					<>
						<div className={styles.logo}>{data?.img}</div>
						<div className={styles.countList}>
							{data &&
								dataPrices[data.method].map((item: DataPrice) => (
									<div
										key={item.count}
										onClick={() => setSelected(item)}
										className={clsx(
											styles.countItem,
											item.title === selected?.title && styles.active
										)}
									>
										{item.title} - {item.count}¥
									</div>
								))}
						</div>

						<Button
							onClick={onHandlerClick}
							modifiers={['gold', 'full', 'small']}
							title={messages[locale].confirm}
							disabled={!selected || isLoading}
							className={styles.buttonSelect}

						/>
					</>
				)}
			</div>
		</Modal>
	)
}
