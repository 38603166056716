import clsx from 'clsx'
import { FC, PropsWithChildren, useEffect, useState } from 'react'

import styles from './ActionIcon.module.scss'
import useOutsideClick from '@/shared/hooks/useOutsideClick'

interface IProps extends PropsWithChildren {
	icon: React.ReactNode
	className?: string
}

export const ActionIcon: FC<IProps> = ({ icon, children, className }) => {
	const [isOpen, setIsOpen] = useState(false)
	const { ref, isActive } = useOutsideClick(false)

	useEffect(() => {
		setIsOpen(isActive)
	}, [isActive])

	return (
		<div ref={ref} className={(styles.wrapper, className)}>
			<div
				className={clsx(styles.icon, isOpen && styles.active)}
				onClick={() => {
					setIsOpen(prev => !prev)
				}}
			>
				{icon}
			</div>
			<div className={clsx(styles.content, isOpen && styles.open)}>
				{children}
			</div>
		</div>
	)
}
