import cn, { clsx } from 'clsx'
import { FC } from 'react'

import styles from './EarnedDailyRewardModal.module.sass'
import { IEarnedDailyRewardModal } from './earned-daily-reward-modal.interface'
import rewardLeaves from '@/Assets/img/reward-leaves.webp'
import rewardMoneyMobile from '@/Assets/img/reward-money-mobile.webp'
import rewardMoney from '@/Assets/img/reward-money.webp'
import { ReactComponent as RewardSparklesMobile } from '@/Assets/svg/reward-sparkles-mobile.svg'
import { ReactComponent as RewardSparkles } from '@/Assets/svg/reward-sparkles.svg'
import { useGetBonuses } from '@/features/profile/user-data/api'
import { useIsMobile } from '@/shared/hooks/useIsMobile'

const EarnedDailyRewardModal: FC<IEarnedDailyRewardModal> = ({
	setIsModalOpened
}) => {
	useGetBonuses()
	const { isMobile } = useIsMobile()

	const closeModal = () => {
		setIsModalOpened(false)
	}

	return (
		<>
			<div className={styles.wrapper}>
				<div className={styles.content}>
					<h3>
						Congratulations! <br /> You've earned your daily reward of
					</h3>
					<div className={styles.reward}>
						<h3>+500 betcoins</h3>
						<div className={styles.light} />
					</div>
					<button
						className={cn('buttonRedHoverBlackBorderGold', styles.button)}
						type='button'
						data-content='ok'
						onClick={closeModal}
					/>
					<div className={styles.rewardMoney}>
						{!isMobile ? (
							<>
								<RewardSparkles />
								<img src={rewardMoney} alt='reward money' />
							</>
						) : (
							<>
								<RewardSparklesMobile />
								<img src={rewardMoneyMobile} alt='reward money' />
							</>
						)}
					</div>
				</div>

				<div className={styles.rewardLeaves}>
					<img
						className={clsx(isMobile && styles.none)}
						src={rewardLeaves}
						alt='leaves'
					/>
				</div>
			</div>

			<div
				tabIndex={-1}
				role='switch'
				aria-checked='false'
				id='backdrop'
				onKeyDown={() => {}}
				className={styles.backdrop}
			/>
		</>
	)
}

export default EarnedDailyRewardModal
