import React, { Suspense, useLayoutEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import Layout from '@/shared/ui/layout'
import { Loader } from '@/shared/ui/loader/Loader'

export function randomInteger(min: number, max: number) {
	let rand = min + Math.random() * (max + 1 - min)
	return Math.floor(rand)
}

export const QuizPage = () => {
	const navigate = useNavigate()
	const urlQuiz = ['/quiz-care', '/quiz-farm', '/quiz-school']

	useLayoutEffect(() => {
		window.location.pathname = urlQuiz[randomInteger(0, 2)]
	}, [])

	return (
		<Suspense fallback={<Loader />}>
			<Layout
				title='quiz'
				description='game_description'
				titlePage='game_title'
				descriptionMeta='game_description'
				position='center'
				fullWidth={false}
				hiddenBreadcrumbs
			></Layout>
		</Suspense>
	)
}
