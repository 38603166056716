import Cookies from 'js-cookie'
import { PropsWithChildren, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import '@/Assets/sass/default.sass'
import {
	ForgotPassword,
	ForgotPasswordCode,
	HaveCode,
	Login,
	Registration
} from '@/features/auth/ui/'
import FourDigitCode from '@/features/auth/ui/FourDigitCode'
import { NotFound } from '@/pages/not-found'
import { FINAL_QUIZ } from '@/shared/constants'
import { useLocalStorage } from '@/shared/hooks/useLocalStorage'
import { useQueryParams } from '@/shared/hooks/useQueryParams'

export type TStepAuth =
	| 'login'
	| 'registration'
	| 'forgot-password'
	| 'forgot-password-code'
	| 'four-digit-code'
	| 'have-code'
	| ''

export const ProtectedRoute: React.FC<
	PropsWithChildren & { isMain?: boolean; hiddenNoAuth?: boolean }
> = ({ children, isMain, hiddenNoAuth }) => {
	const { localValue } = useLocalStorage(FINAL_QUIZ)

	const params = useQueryParams()

	const [pageName, setPageName] = useState<TStepAuth>(
		params?.ref || localValue ? 'registration' : 'login'
	)
	const [dataAuthForm, setDataAuthForm] = useState<{
		[x: string]: string
	}>({})

	const refresh = Cookies.get('refreshToken') || localStorage.getItem('refresh')

	useEffect(() => {
		if (!params?.ref) return
		setPageName('registration')
	}, [params?.ref])

	if (refresh && hiddenNoAuth) return <NotFound />
	if (!refresh && hiddenNoAuth) return children
	if (isMain && !params?.ref) return children
	if (!refresh) {
		// Проверка авторизации
		switch (pageName) {
			case 'registration':
				return (
					<Registration
						refCode={params?.ref}
						page={setPageName}
						setDataAuthForm={setDataAuthForm}
					/>
				)
			case 'have-code':
				return <HaveCode page={setPageName} />
			case 'forgot-password':
				return <ForgotPassword page={setPageName} setEmail={setDataAuthForm} />
			case 'forgot-password-code':
				return (
					<ForgotPasswordCode page={setPageName} email={dataAuthForm?.email} />
				)
			case 'four-digit-code':
				return <FourDigitCode page={setPageName} authDataForm={dataAuthForm} />
			case 'login':
				return <Login page={setPageName} />
			default:
				return <></>
		}
	}
	return <>{children}</>
}
