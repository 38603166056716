import React, { FC } from 'react'

import { useReferralLink } from '../api/useReferralLink'

import styles from './ReferralProgram.module.scss'
import { ReferralBlockInfo } from '@/entities/profile/referral-block-info'
import { CopyJSX } from '@/shared/assets/common/svg'
import { useAlertMessage } from '@/shared/hooks/useAlertMessage'
import { Button } from '@/shared/ui/form'
import { Loader } from '@/shared/ui/loader/Loader'
import { getMessageLocality } from '@/shared/utils'

export const ReferralProgram = () => {
	const { addAlertMessage } = useAlertMessage()
	const { data, isLoading } = useReferralLink()
	const dataReferral = [
		{
			name: getMessageLocality('referral_invite'),
			description: getMessageLocality('referral_invite_description')
		},
		{
			name: getMessageLocality('referral_earn'),
			description: getMessageLocality('referral_earn_description')
		},
		{
			name: getMessageLocality('referral_link'),
			description: data?.url || '',
			isLink: true,
			icon: <CopyJSX />
		}
	]

	const onCopyLink = (text: string, isLink?: boolean) => {
		if (!isLink) return
		navigator.clipboard.writeText(text).then(
			function () {
				addAlertMessage('success', 'info')
			},
			function (err) {
				console.error('Произошла ошибка при копировании текста: ', err)
			}
		)
	}

	if (!data?.url && !isLoading) return null
	if (isLoading) return <Loader isMini />

	return (
		<>
			<div className={styles.wrapper}>
				<div className={styles.title}>
					{getMessageLocality('referral_work')}
				</div>
				<div className={styles.list}>
					{dataReferral.map((item, i) => (
						<ReferralBlockInfo
							key={i}
							count={i + 1}
							name={item.name}
							description={item.description}
							icon={item.icon}
							isLink={item.isLink}
							onClick={() => {
								onCopyLink(item.description, item.isLink)
							}}
						/>
					))}
				</div>
			</div>
			<Button
				classNameContainer={styles.button}
				onClick={() => onCopyLink(dataReferral[2].description, true)}
				modifiers={['gold']}
				title={getMessageLocality('copy_link')}
			/>
		</>
	)
}
