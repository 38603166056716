import { useMemo, useState } from 'react'
import { LinkItemMenuProps } from '../types/global.types';

export const useBreadCrumbs = (DataTabMenu: Array<LinkItemMenuProps>): any => {
	const [breadCrumbs, setBreadCrumbs] = useState<Array<string>>([])
	const [navBreadCrumbs, setNavBreadCrumbs] = useState<
		Array<{ name: string; link: string | undefined } | null>
	>([])

	useMemo(() => {
		const path = window.location.pathname
		let arrPath = path.slice(1, path.length)
		if (arrPath.length > 0) {
			setBreadCrumbs(arrPath.split('/'))
		} else setBreadCrumbs([])
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [DataTabMenu])

	useMemo(() => {
		const newBreadCrumbs = breadCrumbs
			.map((item: string, index: number) => {
				if (item === 'blog' || item === 'sports') {
					document.getElementById('App')?.classList.add('AppBlogBackground')
					document
						.getElementById('App')
						?.classList.remove('AppMatchCentreBackground')
					document
						.getElementById('App')
						?.classList.remove('AppLeaderboardBackground')
				} else if (item === 'match-centre') {
					document
						.getElementById('App')
						?.classList.add('AppMatchCentreBackground')
					document.getElementById('App')?.classList.remove('AppBlogBackground')
					document
						.getElementById('App')
						?.classList.remove('AppLeaderboardBackground')
				} else if (item === 'leaderboard') {
					document
						.getElementById('App')
						?.classList.add('AppLeaderboardBackground')
					document.getElementById('App')?.classList.remove('AppBlogBackground')
					document
						.getElementById('App')
						?.classList.remove('AppMatchCentreBackground')
				} else {
					document.getElementById('App')?.classList.remove('AppBlogBackground')
					document
						.getElementById('App')
						?.classList.remove('AppLeaderboardBackground')
					document
						.getElementById('App')
						?.classList.remove('AppMatchCentreBackground')
				}
				if (
					item === 'profile' &&
					(breadCrumbs[index + 1] === 'account' ||
						breadCrumbs[index + 1] === 'logout')
				) {
					return { name: item + '_route', link: undefined }
				} else if (item === 'account') {
					return null
				} else if (item === 'logout') {
					return null
				} else if (!isNaN(Number.parseInt(item))) {
					return null
				} else if (
					!isNaN(Number.parseInt(breadCrumbs[breadCrumbs.length - 1]))
						? index !== breadCrumbs.length - 2
						: index !== breadCrumbs.length - 1
				) {
					if (!isNaN(Number.parseInt(breadCrumbs[index + 1]))) {
						return {
							name: item + '_route',
							link: breadCrumbs
								.slice(0, index + 1)
								.reduce(
									(prev: string, current: string, indexReducer: number) => {
										if (indexReducer === index) {
											if (current === 'profile') {
												return `${prev}/profile/account/${
													breadCrumbs[index + 1]
												}`
											} else
												return `${prev}/${current}/${breadCrumbs[index + 1]}`
										} else {
											if (current === 'profile') {
												return `${prev}/profile/account`
											} else return `${prev}/${current}`
										}
									},
									''
								)
						}
					} else
						return {
							name: item + '_route',
							link: breadCrumbs
								.slice(0, index + 1)
								.reduce((prev: string, current: any) => {
									if (current === 'profile') {
										return `${prev}/profile/account`
									} else return `${prev}/${current}`
								}, '')
						}
				} else return { name: item + '_route', link: undefined }
			})
			.filter((item: any) => item !== null)
		newBreadCrumbs.unshift({ name: 'home_route', link: '/' })
		setNavBreadCrumbs(newBreadCrumbs)
	}, [breadCrumbs])

	return navBreadCrumbs
}
