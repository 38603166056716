import { toFormData } from 'axios'
import { addYears, format, startOfYear } from 'date-fns'
import { DateTime } from 'luxon'

import { axiosInstance } from '@/shared/api'
import { getAnalyticsUrl } from '@/shared/api/config/api.config'
import {
	IAllAnalytics,
	IAnalytic,
	ICreateAnalyticsData
} from '@/shared/types/analytics.types'
import {
	IPaginationData,
	IPaginationDataWithDate
} from '@/shared/types/global.types'

const currentDate = new Date()
// Прибавить один год к текущей дате
const nextYear = addYears(currentDate, 1)

const utcDate = DateTime.now().toUTC().toFormat("yyyy-MM-dd'T'HH:mm:ss'Z'")

// Получить начало следующего года
const startOfNextYear = startOfYear(nextYear)

const AnalyticsService = {
	async getAll({
		page = 1,
		count = 21,
		fromDate = utcDate,
		author_name,
		toDate = format(startOfNextYear, `yyyy-MM-dd'T'HH:mm:ss`)
	}: IAnalytic.IGetParams) {
		return axiosInstance.get<IAnalytic.DataAnalytic[]>(
			getAnalyticsUrl(`/list`),
			{
				params: {
					page,
					count,
					fromDate,
					author_name,
					toDate
				}
			}
		)
	},

	async getById(id: number) {
		return axiosInstance.get<IAnalytic.DataMoreAnalytic>(
			getAnalyticsUrl(`/get/${id}`)
		)
	},

	async buy(id: number) {
		return axiosInstance.get<string>(getAnalyticsUrl(`/buy/${id}`))
	},

	async create(data: ICreateAnalyticsData) {
		return axiosInstance.post<string>(getAnalyticsUrl(`/new`), data)
	},

	async edit(id: number, data: Partial<ICreateAnalyticsData>) {
		return axiosInstance.patch<string>(getAnalyticsUrl(`/edit/${id}`), data)
	},

	async delete(id: number) {
		return axiosInstance.delete<string>(getAnalyticsUrl(`/delete/${id}`))
	},

	async getExpertsList(data?: IAnalytic.GetExperts) {
		return axiosInstance.get<IAnalytic.ExpertData[]>(
			getAnalyticsUrl(`/expert_by_rating_name`),
			{
				params: data
			}
		)
	},

	async getExpert(id?: number) {
		return axiosInstance.get<IAnalytic.ExpertData>(
			getAnalyticsUrl(`/get_expert/${id}`)
		)
	},

	async getRattingList(data?: IPaginationData) {
		return axiosInstance.get<IAnalytic.RatingData[]>(
			getAnalyticsUrl(`/rating_names_list`),
			{
				params: data
			}
		)
	},
	async createExpertsList(data: IAnalytic.CreateExpert) {
		return axiosInstance.post<string>(
			getAnalyticsUrl(`/create_expert`),
			toFormData(data)
		)
	}
}

export default AnalyticsService
