import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { useAlertMessage } from '@/shared/hooks/useAlertMessage'
import { useErrorAlert } from '@/shared/hooks/useErrorAlert'
import UserService from '@/shared/services/user.service'
import { IChangePasswordData } from '@/shared/types/user.types'

export const useChangePassword = () => {
	const { addAlertMessage } = useAlertMessage()
	const { addErrorAlert } = useErrorAlert()
	const { mutateAsync: changePassword } = useMutation(
		['changePassword'],
		(changePasswordData: IChangePasswordData) =>
			UserService.changePassword(changePasswordData),
		{
			onSuccess: res => {},
			onError: (err: AxiosError) => {
				console.error(err)
				addErrorAlert(err)
			},
			retry: 0
		}
	)

	return { changePassword }
}
