import cn from 'clsx'
import { FC } from 'react'

import styles from './BettingCoefficient.module.sass'
import { reFormatNum } from '@/shared/utils'

interface IProps {
	status:
		| 'won'
		| 'loss'
		| 'none'
		| 'progress'
		| 'refund'
		| 'refund_error'
		| 'win_half_1'
		| 'win_half_2'
		| 'lose_half_1'
		| 'lose_half_2'

	coeff: number
	className?: string
}
const BettingCoefficient: FC<IProps> = ({ coeff, status, className }) => {
	return (
		<div className={cn(styles.coefficient, className, styles[status])}>
			<span>{reFormatNum(coeff)}</span>
		</div>
	)
}

export default BettingCoefficient
