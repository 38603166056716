import clsx from 'clsx'
import React, { FC } from 'react'

import styles from './CollectButton.module.scss'
import rewardGift from '@/Assets/img/reward-gift.webp'
import rewardSparklesAround from '@/Assets/img/reward-sparkles-around.webp'
import { ReactComponent as RewardGiftSparkles } from '@/Assets/svg/reward-gift-sparkles.svg'
import { messages } from '@/i18n/messages'
import { useAppSelector } from '@/shared/model'

interface IProps {
	disabled?: boolean
	onClick: () => void
	classContainer?: string
}

export const CollectButton: FC<IProps> = ({
	onClick,
	disabled,
	classContainer
}) => {
	const { locale } = useAppSelector(state => state.defaultReducer)
	return (
		<div
			className={clsx(
				styles.collectContainer,
				disabled && styles.inactive,
				classContainer
			)}
		>
			<div className={styles.gift}>
				<img src={rewardGift} alt='gift' />
				<img src={rewardSparklesAround} alt='sparkles around' />
				<RewardGiftSparkles />
			</div>
			<button
				className={clsx('buttonRedHoverBlackBorderGold', styles.button)}
				onClick={onClick}
				type='button'
				data-content={messages[locale].collect}
				disabled={disabled}
			/>
		</div>
	)
}
