import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { useAlertMessage } from '@/shared/hooks/useAlertMessage'
import UserService from '@/shared/services/user.service'
import { TypeChangeUserData } from '@/shared/types/user.types'

export const useEditData = () => {
	const queryClient = useQueryClient()

	const { addAlertMessage, addErrorAlert } = useAlertMessage()

	const { mutateAsync: changeUserData } = useMutation(
		['edit user data'],
		(editUserData: TypeChangeUserData) =>
			UserService.editUserData(editUserData),
		{
			onSuccess: res => {
				queryClient.invalidateQueries(['get user data'])
				addAlertMessage(res.data)
			},
			onError: (error: AxiosError) => {
				queryClient.invalidateQueries(['get user data'])
				// addErrorAlert(error)
			}
		}
	)

	return { changeUserData }
}
