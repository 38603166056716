// Библиотечные компоненты
import clsx from 'clsx'
import { format, parseISO } from 'date-fns'
import { FC, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Link, useNavigate } from 'react-router-dom'

import styles from './BlockCard.module.scss'
import { VersusJSX } from './svg'
import { CoinsJSX } from './svg/CoinsJSX'
import { AnalyticBet } from '@/features/sports/ui'
import { BlockedIconJSX } from '@/shared/assets/common/svg'
import { IAnalytic } from '@/shared/types/analytics.types'
import CollapsibleText from '@/shared/ui/collapsible'
import { Loader } from '@/shared/ui/loader/Loader'
// Стили
// Типизация
import { reFormatNum } from '@/shared/utils'

interface IProps {
	img?: string
	video?: string
	opponent_one_name?: string
	opponent_two_name?: string
	opponent_one_image?: string
	opponent_two_image?: string
	start_date?: string
	title?: string
	description: string
	visible?: boolean
	price?: number
	to: string
	closeZoneClick?: () => void
	isSide?: boolean
	isForcast?: boolean
	isImageBackground?: string
	dataMore?: IAnalytic.DataMoreAnalytic
	data: IAnalytic.DataAnalytic
	isLoading?: boolean
	onOpenMore?: () => void
}

export const BlogCard: FC<IProps> = ({
	img,
	title,
	description,
	visible,
	opponent_one_name,
	opponent_two_name,
	opponent_one_image,
	opponent_two_image,
	start_date,
	to,
	isSide,
	price,
	closeZoneClick,
	isImageBackground,
	isForcast,
	isLoading,
	onOpenMore,
	dataMore,
	data
}) => {
	const parsedDate = start_date && parseISO(start_date)
	const formattedDate = parsedDate && format(parsedDate, 'dd.MM - HH:mm')
	const navigate = useNavigate()
	const updateDescription = description.replaceAll('\n', '<br/>')
	const [isOpen, setIsOpen] = useState(false)

	const onToggleIsOpen = () => {
		if (isForcast) {
			setIsOpen(prev => !prev)

			onOpenMore?.()
		} else {
			navigate(to)
		}
	}
	useEffect(() => {
		if (dataMore && dataMore?.id !== data.id) {
			setIsOpen(false)
		}
	}, [dataMore])

	useEffect(() => {
		if (!isForcast) {
			setIsOpen(false)
		}
	}, [isForcast])

	const mockImageUrl = `https://www.gov.kz/uploads/2020/4/30/bfd6278440a4ea87157ce1813d2ed101_original.187292.jpg`
	return (
		<div className={styles.allWrapper}>
			<div
				className={clsx(
					styles.wrapper,
					isSide && styles.sideWrapper,
					isForcast && styles.forcastWrapper,
					isOpen && styles.isOpen
				)}
				onClick={onToggleIsOpen}
				style={{
					// backgroundImage: !isImageBackground
					// 	? `url(${isImageBackground})`
					// 	: '',
					backgroundSize: 'cover'
				}}
			>
				{img ? (
					<img src={img} alt='go' />
				) : opponent_one_image && opponent_two_image ? (
					<div className={styles.cardCommand}>
						<div className={styles.commandA}>
							<div className={styles.circle}>
								<img
									key={opponent_one_image}
									src={opponent_one_image}
									alt='group'
								/>
							</div>
							<div className={styles.name}>{opponent_one_name}</div>
						</div>
						<div className={styles.score}>
							<VersusJSX />
							{formattedDate}
						</div>
						<div className={styles.commandB}>
							<div className={styles.circle}>
								<img
									key={opponent_two_image}
									src={opponent_two_image}
									alt='group'
								/>
							</div>
							<div className={styles.name}>{opponent_two_name}</div>
						</div>
					</div>
				) : null}
				{isForcast && !isOpen && (
					<div className={styles.titleMobi}>{title}</div>
				)}
				{isSide && (
					<div className={styles.isSideDescription}>{description}</div>
				)}
				{!isSide && !isForcast && (
					<div className={styles.info}>
						<div className={styles.title}>{title}</div>
						<div
							className={styles.description}
							dangerouslySetInnerHTML={{
								__html: visible
									? description
									: 'xxxxxxxx xxxxxxxx xxxxxxx xxxxxxxx xxxxxxxx xxxxxxx'
							}}
						/>
						{visible && (
							<Link className={styles.readMore} to={to}>
								<FormattedMessage id='read_more' />
							</Link>
						)}

						{!visible && (
							<div onClick={closeZoneClick} className={styles.closeCard}>
								<BlockedIconJSX />
								<div className={styles.unlock}>
									<FormattedMessage id='open_for' />
									{reFormatNum(price)} ₿
								</div>

								<div className={styles.unlockVisible}>
									<FormattedMessage id='blocked' />
								</div>
								<CoinsJSX />
							</div>
						)}
					</div>
				)}
			</div>
			{isOpen && (
				<div className={styles.forcastInfo}>
					{isLoading && <Loader isMini absolute />}
					<div className={styles.title}>{title}</div>
					<div className={styles.moreDescription}>
						<CollapsibleText countString={4} text={updateDescription} />
					</div>

					{dataMore && <AnalyticBet data={dataMore} isForcast />}
				</div>
			)}
		</div>
	)
}
