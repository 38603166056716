import { typeAction } from '../types'

import { school } from '@/Assets/img/Home/two/category/school.svg'
import { typeBlog } from '@/shared/store/types'

const defaultArticleState: typeBlog.StateReducer = {
	news: {
		items: [],
		page: 1,
		count: 21,
		total_count: 0
	},
	school: {
		categories: [],
		list_by_category: {
			items: [],
			page: 1,
			count: 21,
			total_count: 0
		}
	}
}

export default function blog(state = defaultArticleState, action: typeAction) {
	const { type, value } = action
	switch (type) {
		case 'SET_NEWS':
			return { ...state, news: value }
		case 'SET_SCHOOL_LIST_CATEGORY': {
			const { value } = action

			return { ...state, school: { ...state.school, categories: value } }
		}
		case 'SET_SCHOOL_LIST_BY_CATEGORY':
			state.school.list_by_category = value
			return { ...state }
		default:
			return state
	}
}
