import clsx from 'clsx'
import { FC } from 'react'
import { FormattedMessage } from 'react-intl'

import styles from './PaymentHistoryTable.module.scss'
import TableItem from './item'
import TableItemMobile from './item-mobille'
import { IPaymentHistoryTable } from './payment-history-table.interface'
import { useIsMobile } from '@/shared/hooks/useIsMobile'

const tableHeadings = ['id', 'section', 'date', 'product', 'price', 'detail']

const PaymentHistoryTable: FC<IPaymentHistoryTable> = ({
	paymentHistoryItems,
	setPaymentDetailModal,
	isOpenModal
}) => {
	const { isMobile } = useIsMobile(1000)
	return (
		<div className={styles.container}>
			{isMobile && (
				<div className={styles.list}>
					{paymentHistoryItems?.length ? (
						paymentHistoryItems.map(item => (
							<TableItemMobile
								key={item.id}
								tableItem={item}
								setPaymentDetailModal={setPaymentDetailModal}
							/>
						))
					) : (
						<div className={styles.notFound}>
							<p style={{ color: 'black', textAlign: 'center' }}>
								<FormattedMessage id='message_404' />
							</p>
						</div>
					)}
				</div>
			)}

			{!isMobile && (
				<table>
					<thead className={styles.tableHeader}>
						{tableHeadings.map(heading => (
							<p key={heading} className={styles[heading]}>
								<FormattedMessage id={heading} />
							</p>
						))}
					</thead>

					<div className={clsx(styles.tableBody)}>
						{paymentHistoryItems?.length ? (
							paymentHistoryItems?.map(item => (
								<TableItem
									key={item.id}
									tableItem={item}
									setPaymentDetailModal={setPaymentDetailModal}
								/>
							))
						) : (
							<div className={styles.notFound}>
								<p style={{ color: 'black', textAlign: 'center' }}>
									<FormattedMessage id='message_404' />
								</p>
							</div>
						)}
					</div>
				</table>
			)}

			{isOpenModal && <div className={clsx(styles.blur)} />}
		</div>
	)
}

export default PaymentHistoryTable
