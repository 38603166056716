import { useQuery } from '@tanstack/react-query'

import AnalyticsService from '@/shared/services/analytics.service'
import { IPaginationData } from '@/shared/types/global.types'

export const useGetRating = (data?: IPaginationData) => {
	return useQuery(
		['get rating list', data],
		() => AnalyticsService.getRattingList(data),
		{
			select: ({ data }) => data
		}
	)
}
