import { useInfiniteQuery } from '@tanstack/react-query'
import { useEffect, useState } from 'react'

import UserService from '@/shared/services/user.service'
import {
	IHistoryFiltersForm,
	IPaginationData
} from '@/shared/types/global.types'
import { IBet, IExpressList } from '@/shared/types/sport.types'

export const useBettingHistory = (
	historyFilters: IHistoryFiltersForm | null
) => {
	const [bettingHistory, setListBettingHistory] = useState<Array<IExpressList>>(
		[]
	)

	let allHistoryData = {
		page: 1,
		count: 21
	} as IPaginationData
	const {
		isLoading,
		isFetching,
		isFetchingNextPage,
		data: bettingHistoryPages,
		hasNextPage,
		fetchNextPage
	} = useInfiniteQuery(
		['get all tournaments', historyFilters],
		({ pageParam = 1 }) => {
			allHistoryData.page = pageParam
			return UserService.getBetsList(
				{ ...allHistoryData, ...historyFilters } || {}
			)
		},
		{
			retry: 0,
			getNextPageParam: (_lastPage, pages) => pages.length + 1
			// keepPreviousData: true
		}
	)

	useEffect(() => {
		let newHistory: Array<IExpressList> = []
		bettingHistoryPages?.pages.map(item => newHistory.push(...item.data))
		setListBettingHistory(newHistory)
	}, [bettingHistoryPages])

	return {
		isLoading,
		isFetching,
		isEmpty: bettingHistoryPages?.pages.at(-1)?.data?.length === 0,
		isFetchingNextPage,
		bettingHistory,
		hasNextPage,
		fetchNextPage
	}
}
