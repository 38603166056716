import { format } from 'date-fns'
import { FC } from 'react'
import { FieldValues, useForm } from 'react-hook-form'

import styles from './EditData.module.scss'
import LevelAvatar from '@/entities/profile/level-avatar'
import { useEditData, useProfileData } from '@/features/profile/api'
import ChangePasswordFields from '@/features/profile/change-password-fields'
import EditDataForm from '@/features/profile/edit-data-form'
import { useAlertMessage } from '@/shared/hooks/useAlertMessage'
import { useIsMobile } from '@/shared/hooks/useIsMobile'
import { Loader } from '@/shared/ui/loader/Loader'

const EditData: FC = () => {
	const { isLoading, levelType, userData } = useProfileData()
	const { isMobile } = useIsMobile()
	const { changeUserData } = useEditData()
	const { addErrorAlert } = useAlertMessage()

	const methods = useForm({
		mode: 'onBlur'
	})

	const onSubmit = async (data: FieldValues) => {
		const { code, ...restCode } = data
		const formattedBirthday =
			data.birthday && format(data.birthday, 'yyyy-MM-dd')

		const phone =
			data?.phone && code ? code + data?.phone?.replace(/\D/g, '') : undefined

		try {
			await changeUserData({
				...restCode,
				phone: phone?.replace('+', ''),
				birthday: formattedBirthday
			})
		} catch (error: any) {
			addErrorAlert(error)
		}
	}

	if (isLoading) return <Loader />

	return (
		<>
			<div className={styles.wrapper}>
				{isMobile && (
					<div className={styles.icon}>
						<LevelAvatar levelType={levelType} name={userData?.name} />
					</div>
				)}

				<EditDataForm methods={methods} onSubmit={onSubmit} />
				<div className={styles.verticalLine} />
				<ChangePasswordFields onSubmitData={onSubmit} methodsData={methods} />
			</div>
		</>
	)
}

export default EditData
