import { useMutation } from '@tanstack/react-query'

import UserService from '@/shared/services/user.service'
import { IGetDeposit } from '@/shared/types/user.types'

export const useDeposit = () => {
	return useMutation(['get deposit'], (data: IGetDeposit) =>
		UserService.getDeposit(data)
	)
}
