import { QuizLayout } from '@/features/quiz'
import { FC } from 'react'

import { TQuiz } from '../..'

import { backgroundQuiz_1, image_1, image_1_mobi } from '@/Assets/img/quiz'
import { useIsMobile } from '@/shared/hooks/useIsMobile'
import { QuizLayoutOne } from '@/shared/ui/quiz-layout/quiz-layout-1'
import { getMessageLocality } from '@/shared/utils'

export const QuizThree: FC<TQuiz> = ({ onNextStep }) => {
	const { isMobile } = useIsMobile(768)

	return (
		<QuizLayout background={backgroundQuiz_1}>
			<QuizLayoutOne
				image={isMobile ? image_1_mobi : image_1}
				onNextStep={onNextStep}
				title={getMessageLocality('questionThreeVariant3')}
			/>
		</QuizLayout>
	)
}
