import { useQuery, useQueryClient } from '@tanstack/react-query'

import UserService from '@/shared/services/user.service'

export const useGetBonuses = () => {
	const queryClient = useQueryClient()

	return useQuery(['getBonuses'], () => UserService.getBonuses(), {
		select: ({ data }) => data,
		onSuccess: () => {
			queryClient.invalidateQueries(['get user data'])
		}
	})
}
