import { ITabData } from './tabs.interface'

export const tabsData: ITabData[] = [
	{
		label: 'Profile',
		name: 'account-profile'
	},
	{
		label: 'Edit Profile',
		name: 'edit-info'
	},
	{
		label: 'Payment History',
		name: 'payment-history'
	},
	{
		label: 'Betting History',
		name: 'betting-history'
	},
	{
		label: 'Deposit',
		name: 'deposit-history'
	}
]
