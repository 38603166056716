import { FC, Suspense } from 'react'

import '@/Assets/sass/pages/Sports/Games.sass'
import { useIsMobile } from '@/shared/hooks/useIsMobile'
import Layout from '@/shared/ui/layout'
import { Loader } from '@/shared/ui/loader/Loader'
import { GameWindow } from '@/widgets/games'

export const GameDetail: FC = () => {
	const { isMobile } = useIsMobile(1180)
	return (
		<Suspense fallback={<Loader />}>
			<Layout
				title='game_title'
				description='game_description'
				titlePage='game_title'
				descriptionMeta='game_description'
				position='center'
				fullWidth={isMobile}
				hiddenBreadcrumbs
				hiddenBreadcrumbsAll
			>
				<>
					<GameWindow />
				</>
			</Layout>
		</Suspense>
	)
}
