import clsx from 'clsx'
import { format } from 'date-fns'
import React, { FC, useEffect, useState } from 'react'
import { RiRefund2Fill } from 'react-icons/ri'

import { IBettingHistoryTableItem } from '../betting-history-table.interface'
import {
	TStatusBet,
	isLoseHalf,
	isWinHalf,
	useStatusBet
} from '../item/useStatusBet'

import styles from './ItemMobile.module.scss'
import { ReactComponent as ArrowIcon } from '@/Assets/svg/arrow-select.svg'
import {
	BettingLoss,
	BettingNoCalculate,
	BettingWin
} from '@/Assets/svg/betting'
import { useTranslationField } from '@/shared/hooks/useTranslationField'
import { timeFormatter } from '@/shared/utils/timeFormatter'
import { getMessageLocality } from '@/shared/utils'

export const ItemMobile: FC<IBettingHistoryTableItem> = ({ bet }) => {
	const [isOpen, setIsOpen] = useState(false)
	const { getField } = useTranslationField()

	const { getBetMessage, getBetMessageExpress, getBetStatus, getStatus } =
		useStatusBet()

	const onToggle = () => setIsOpen(prev => !prev)

	const date = format(new Date(bet.createdAt), 'dd/MM/yyyy')

	const isExpress = Array.isArray(bet.game_list)

	const gameList = Array.isArray(bet.game_list)
		? bet.game_list
		: [bet.game_list]

	const isCountEnd =
		gameList.filter(item =>
			['True', 'False', ...isWinHalf, ...isLoseHalf].includes(item.status)
		)?.length || 0

	const status = getStatus(bet)
	const message = getBetMessage(bet)

	

	return (
		<div className={styles.wrapper}>
			<div className={styles.header} onClick={onToggle}>
				<div className={styles.breadcrumbs}>
					<span className={styles.date}>
						{date} <span> / </span>
					</span>
					<span> # {bet.id} /</span>
					<span> {getMessageLocality(bet.type_of_bet)}</span>
				</div>
				<button className={clsx(styles.arrow, isOpen && styles.open)}>
					<ArrowIcon className={''} />
				</button>
			</div>
			<div className={styles.body}>
				<div className={clsx(styles.row, styles.weight, styles.eventRow)}>
					<div className={styles.eventTitle}>
						<svg
							width='16'
							height='17'
							viewBox='0 0 16 17'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'
						>
							<path
								d='M7.27604 0.573351L3.16059 2.63108C2.15989 3.13143 1.65954 3.3816 1.65954 3.77778C1.65954 4.17395 2.15989 4.42413 3.16059 4.92448L7.27604 6.9822C7.83874 7.26355 8.12009 7.40423 8.42274 7.40423C8.72539 7.40423 9.00674 7.26355 9.56944 6.9822L13.6849 4.92448C14.6856 4.42413 15.1859 4.17395 15.1859 3.77778C15.1859 3.3816 14.6856 3.13143 13.6849 2.63108L9.56944 0.573351C9.00674 0.292001 8.72539 0.151326 8.42274 0.151326C8.12009 0.151326 7.83874 0.292001 7.27604 0.573351Z'
								fill='#443E3F'
							/>
							<path
								fill-rule='evenodd'
								clip-rule='evenodd'
								d='M3.76837 7.04492C4.43943 7.4508 5.34848 7.90533 6.532 8.49709C7.25794 8.86006 7.62091 9.04154 8.00338 9.10466C8.27985 9.15029 8.56193 9.15029 8.8384 9.10466C9.22087 9.04154 9.58384 8.86006 10.3098 8.49709C11.4921 7.90591 12.4006 7.45169 13.0714 7.04611L13.6809 7.35082C14.6816 7.85117 15.1819 8.10135 15.1819 8.49752C15.1819 8.8937 14.6816 9.14388 13.6809 9.64423L9.56542 11.702C9.00272 11.9833 8.72137 12.124 8.41872 12.124C8.11607 12.124 7.83472 11.9833 7.27202 11.702L3.15657 9.64423L3.15657 9.64423C2.15587 9.14388 1.65552 8.8937 1.65552 8.49752C1.65552 8.10135 2.15587 7.85117 3.15657 7.35082L3.15657 7.35082L3.76837 7.04492Z'
								fill='#443E3F'
							/>
							<path
								fill-rule='evenodd'
								clip-rule='evenodd'
								d='M3.76837 11.7695C4.43943 12.1754 5.34848 12.6299 6.532 13.2217C7.25794 13.5847 7.62091 13.7662 8.00338 13.8293C8.27985 13.8749 8.56193 13.8749 8.8384 13.8293C9.22087 13.7662 9.58384 13.5847 10.3098 13.2217C11.4921 12.6305 12.4006 12.1763 13.0714 11.7707L13.6809 12.0754C14.6816 12.5758 15.1819 12.826 15.1819 13.2221C15.1819 13.6183 14.6816 13.8685 13.6809 14.3688L9.56542 16.4266C9.00272 16.7079 8.72137 16.8486 8.41872 16.8486C8.11607 16.8486 7.83472 16.7079 7.27202 16.4266L3.15657 14.3688L3.15657 14.3688C2.15587 13.8685 1.65552 13.6183 1.65552 13.2221C1.65552 12.826 2.15587 12.5758 3.15657 12.0754L3.15657 12.0754L3.76837 11.7695Z'
								fill='#443E3F'
							/>
						</svg>
						{getMessageLocality('event')}: {gameList.length}
					</div>
					<div>
						{getMessageLocality('completed')}: {isCountEnd} of {gameList.length}
					</div>
				</div>

				<div className={clsx(styles.row)}>
					<div>{getMessageLocality('total_kef')}</div>
					<div>{bet.total_kef}</div>
				</div>

				<div className={clsx(styles.row)}>
					<div>{getMessageLocality('bet')}</div>
					<div>{bet.amount} ₿</div>
				</div>

				<div
					className={clsx(
						styles.row,
						styles.weight,
						styles.status,
						styles[status]
					)}
				>
					<div className={styles.status}>
						<IconStatus status={status} />
						{message}
					</div>
					<div className={clsx(styles.status)}>{bet.possible_win} ₿</div>
				</div>
			</div>

			<div className={clsx(styles.extraList, isOpen && styles.open)}>
				<div className={styles.wrapperExtra}>
					{gameList.map(item => {
						const statusBet = getBetStatus(item)
						const messageBet = getBetMessageExpress(item)
						return (
							<div className={styles.extraInfo} key={item.id}>
								<div className={styles.body}>
									<div className={styles.event}>
										{getField(item, 'tournament_name')}
									</div>
									<div className={styles.date}>
										{timeFormatter(item.start_date).replace(',', ' ,')}
									</div>
									<div className={clsx(styles.name, styles.weight)}>
										{getField(item, 'opponent_one_name')} -{' '}
										{getField(item, 'opponent_two_name')}
									</div>

									<div className={clsx(styles.row)}>
										<div>
											{isExpress
												? getField(item.markets, 'name')
												: getMessageLocality('total')}
										</div>
										<div>{item.markets.market}</div>
									</div>

									<div className={clsx(styles.row)}>
										<div>{getMessageLocality('status')}</div>

										<div className={styles.extraStatus}>
											<IconStatus status={statusBet} />
											{messageBet}
										</div>
									</div>
								</div>
							</div>
						)
					})}
				</div>
			</div>
		</div>
	)
}

interface IProps {
	status: TStatusBet
}
const IconStatus: FC<IProps> = ({ status }) => {
	if (status === 'won' || status === 'win_half_1' || status === 'win_half_2')
		return <BettingWin />
	if (status === 'loss' || status === 'lose_half_1' || status === 'lose_half_2')
		return <BettingLoss />
	if (status === 'refund_error' || status === 'refund')
		return <RiRefund2Fill fill='#7792b6' />
	return <BettingNoCalculate />
}
