import { useInfiniteQuery } from '@tanstack/react-query'
import { useEffect, useState } from 'react'

import SportService from '@/shared/services/sport.service'
import { IGetAllTournamentsData, ITournament } from '@/shared/types/sport.types'

export const useTableTournament = (sportId: string) => {
	const [tournaments, setDataList] = useState<ITournament[]>([])
	const [pagesCount, setPagesCount] = useState<number>(1)
	const [errorCount, setErrorCount] = useState(0)

	const allTournamentsData = {
		page: 1,
		count: 5,
		sportId: sportId === '0' ? undefined : sportId
	} as IGetAllTournamentsData

	const {
		data,
		isFetching,
		isLoading,
		isFetchingNextPage,
		hasNextPage,
		fetchNextPage,
		refetch
	} = useInfiniteQuery(
		['get all tournaments', allTournamentsData],
		({ pageParam = 1 }) => {
			setPagesCount(pageParam)
			allTournamentsData.page = pageParam
			return SportService.getTournaments({ ...allTournamentsData })
		},

		{
			getNextPageParam: (lastPage, pages) => {
				//TODO Если больше 30 раз ответ
				if (errorCount >= 30) return null

				return pagesCount + 1
			},
			keepPreviousData: true,

			select: data => {
				const res = data.pages.map(item => item.data)
				return { ...data, pages: res }
			},
			retry(failureCount, error: any) {
				if (error.response.status === 404) {
					setErrorCount(prev => prev + 1)
				} else {
					setErrorCount(failureCount)
				}

				return false
			}
		}
	)

	useEffect(() => {
		const result =
			data?.pages.reduce((acc, page) => {
				return [...acc, ...page]
			}, [] as ITournament[]) || []

		if (!isLoading) setDataList(result)
	}, [data?.pages, isFetchingNextPage])

	return {
		tournaments,
		isFetching,
		isLoading,
		isFetchingNextPage,
		hasNextPage,
		fetchNextPage,
		refetch
	}
}
