import { FC, useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'

import styles from './BettingHistory.module.sass'
import { useBettingExpert } from '@/features/expert/api'
import BettingHistoryTable from '@/features/profile/betting-history-table'
import HistoryFilters from '@/features/profile/history-filters'
import { IHistoryFiltersForm } from '@/shared/types/global.types'
import { Loader } from '@/shared/ui/loader/Loader'

export interface IProps {
	sportName?: string | null
}
export const BettingExperts: FC<IProps> = ({ sportName }) => {
	const [historyFilters, setHistoryFilters] =
		useState<IHistoryFiltersForm | null>(null)

	const { isLoading, bettingHistory, refBetting, isFetching } =
		useBettingExpert(historyFilters, sportName)

	return (
		<div className={styles.container}>
			{/* <HistoryFilters setHistoryFilters={setHistoryFilters} /> */}
			{isLoading ? (
				<Loader />
			) : (
				<BettingHistoryTable bettingHistory={bettingHistory} />
			)}
			{isFetching && !isLoading && <Loader isMini />}
			<div ref={refBetting} />
		</div>
	)
}
