import { FC } from 'react'

import styles from './DepositList.module.scss'
import { IDepositPayment } from './deposit-list.interface'

interface IProps {
	data: IDepositPayment
	onSelect?: (data: IDepositPayment) => void
}
export const DepositCard: FC<IProps> = ({ data, onSelect = () => {} }) => {
	return (
		<div className={styles.wrapperCard} onClick={() => onSelect(data)}>
			<div className={styles.image}>{data.img}</div>
			<div className={styles.title}>{data.title}</div>
		</div>
	)
}
