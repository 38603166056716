import { Dispatch, FC, SetStateAction, useEffect } from 'react'
import { FieldValues, useForm } from 'react-hook-form'

import styles from './MobileFilter.module.scss'
import { ActionIcon } from '@/entities/shop'
import { CalendarIconJSX, FilterIconJSx } from '@/shared/assets/common/svg'
import { IHistoryFiltersForm } from '@/shared/types/global.types'
import { CalendarInput, FormWrapper, RadioGroup } from '@/shared/ui/form'
import { MyOptionType } from '@/shared/ui/form/form.interface'

interface IProps {
	options: MyOptionType[]
	setHistoryFilters: Dispatch<SetStateAction<IHistoryFiltersForm | null>>
}

export const MobileFilterHistory: FC<IProps> = ({
	options,
	setHistoryFilters
}) => {
	const form = useForm({
		mode: 'onChange',
		defaultValues: {
			show: options[0].value,
			calendar: [undefined, undefined]
		}
	})

	const { getValues, watch } = form

	const show = watch('show')
	const calendar = watch('calendar')

	const onSubmitForm = (data: FieldValues) => {}

	useEffect(() => {
		const fromDate = calendar[0]
		const toDate = calendar[1]

		setHistoryFilters({ show, fromDate, toDate })
	}, [show, calendar])

	const onBlur = () => {}

	return (
		<FormWrapper
			methods={form}
			onSubmit={onSubmitForm}
			className={styles.wrapper}
		>
			<ActionIcon icon={<CalendarIconJSX />} className={styles.block}>
				<CalendarInput
					name='calendar'
					connect
					inline
					selectsRange
					onBlur={onBlur}
					isClear
				/>
			</ActionIcon>

			<ActionIcon icon={<FilterIconJSx />}>
				<RadioGroup
					containerClassName={styles.filter}
					name='show'
					options={options}
					connect
					onBlur={onBlur}
				/>
			</ActionIcon>
		</FormWrapper>
	)
}
