import { QuizLayout } from '@/features/quiz'
import { FC } from 'react'

import { TQuiz } from '../..'

import {
	backgroundQuiz_4, image_22,
	image_22_mobi
} from '@/Assets/img/quiz'
import { QuizLayoutTwo } from '@/shared/ui/quiz-layout/quiz-layout-2'
import { getMessageLocality } from '@/shared/utils'
import { useIsMobile } from '@/shared/hooks/useIsMobile'

export const QuizTwo: FC<TQuiz> = ({ onNextStep }) => {
	const { isMobile } = useIsMobile(768)

	return (
		<QuizLayout background={backgroundQuiz_4}>
			<QuizLayoutTwo
				image={isMobile ? image_22_mobi : image_22}
				onNextStep={onNextStep}
				title={getMessageLocality('questionTwoVariant3')}
			/>
		</QuizLayout>
	)
}
