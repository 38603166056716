import clsx from 'clsx'
import React, { FC, useEffect, useState } from 'react'
import { SingleValue } from 'react-select'

import styles from './AnalyticBet.module.scss'
import { useTranslationField } from '@/shared/hooks/useTranslationField'
import { typeMarketAnalytic } from '@/shared/store/types'
import { IAnalytic } from '@/shared/types/analytics.types'
import { IGame } from '@/shared/types/sport.types'
import { Button, ReactSelect } from '@/shared/ui/form'
import { MyOptionType } from '@/shared/ui/form/form.interface'
import { getMessageLocality } from '@/shared/utils'
import { addMarketCart } from '@/widgets/sports/bets-basket/useBasket'

interface IProps {
	data: IAnalytic.DataMoreAnalytic
	isForcast?: boolean
}

export const AnalyticBet: FC<IProps> = ({ data, isForcast }) => {
	const cartSession = JSON.parse(
		window.localStorage.getItem('cart') || '[]'
	) as IGame[]

	const { getField } = useTranslationField()
	const [currentMarket, setCurrentMarket] = useState<
		typeMarketAnalytic | undefined
	>()

	const dataList = data?.game?.markets?.map(item => ({
		label: getField(item, 'name') + `— ${item.market}`,
		value: item.pk
	}))

	useEffect(() => {
		setCurrentMarket(data?.game.markets[0])
	}, [])

	const onChangeBetMarket = (newValue: SingleValue<MyOptionType>) => {
		const newCurrentValue = data?.game?.markets.find(
			item => item.pk === newValue?.value
		)

		setCurrentMarket(newCurrentValue)
	}

	const onClick = () => {
		if (!currentMarket) return
		//@ts-ignore
		const game = {
			...data.game,
			markets: data.game?.markets,
			tournament: data.game?.tournament
		} as IGame

		addMarketCart(game, currentMarket.name_en)
	}

	return (
		<div className={clsx(styles.wrapper, isForcast && styles.wrapperForcast)}>
			<div className={styles.title}>{getMessageLocality('your_bet')}</div>
			<div className={styles.betBlock}>
				<ReactSelect
					containerClassName={styles.input}
					name='select'
					isSearchable={false}
					onChange={onChangeBetMarket}
					placeholder={getMessageLocality('your_bet')}
					value={
						currentMarket && {
							label:
								getField(currentMarket, 'name') +
								`— ${currentMarket?.market || ''}`,
							value: currentMarket?.pk || ''
						}
					}
					options={dataList}
					defaultValue={dataList[0] || undefined}
				/>
				<Button
					classNameContainer={styles.button}
					disabled={!currentMarket}
					title={getMessageLocality('place_bet')}
					modifiers={['gold']}
					onClick={onClick}
				/>
			</div>
		</div>
	)
}
