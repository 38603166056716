import { useMutation, useQuery } from '@tanstack/react-query'

import { IResetPasswordForm } from '@/shared/store/user/user.interface'
import AuthService from '@/shared/services/auth/auth.service'

export const useResetPassword = () => {
	return useMutation(['reset password'], (data: IResetPasswordForm) =>
		AuthService.resetPassword(data)
	)
}
