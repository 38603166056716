import clsx from 'clsx'
import { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'

import styles from './ExperienceProgress.module.scss'
import { IExperienceProgress } from './experience-progress.interface'
import { ReactComponent as CupIcon } from '@/Assets/svg/cup-icon.svg'
import ExperienceProgressBar from '@/entities/profile/experience-progress-bar'
import GradientBorderText from '@/entities/profile/gradient-border-text'
import LevelAvatar from '@/entities/profile/level-avatar'
import { avatarIcon } from '@/shared/assets/profile/img'
import { InfoIconJSX } from '@/shared/assets/profile/svg'
import { useAppSelector } from '@/shared/model'
import { reFormatNum } from '@/shared/utils'

const ExperienceProgress: FC<IExperienceProgress> = ({
	rating,
	starData,
	isMobile,
	isRow,
	isHiddenInfo,
	blockExperience,
	infoIcon
}) => {
	const formatter = new Intl.NumberFormat('ru-RU')
	const { locale } = useAppSelector(state => state.defaultReducer)

	return (
		<div className={clsx(styles.container, isRow && styles.containerRow)}>
			{blockExperience ? (
				blockExperience
			) : (
				<LevelAvatar
					levelType={starData.raiting_name_en}
					className={styles.avatar}
					avatarLink={avatarIcon}
				/>
			)}

			{!isHiddenInfo && (
				<div className={styles.info}>
					{isMobile && (
						<h3 className={styles.level}>
							{locale === 'en'
								? starData.raiting_name_en
								: starData.raiting_name_cn}{' '}
							<FormattedMessage id='level' />{' '}
						</h3>
					)}

					<ExperienceProgressBar
						rating={rating}
						ratingName={starData.raiting_name_en}
						starsNumber={starData.star}
					/>
					<div className={styles.rating}>
						<span>{reFormatNum(rating, 'XP')}</span> /
						<span>{reFormatNum(starData.rating_star, 'XP')}</span>
					</div>
					<GradientBorderText
						text={formatter.format(rating)}
						icon={<CupIcon />}
						className={styles.gradientBorderText}
					/>
				</div>
			)}
			{infoIcon && (
				<div className={styles.infoIcon}>
					<Link to={'/profile/bonuses'}>
						<InfoIconJSX />
					</Link>
				</div>
			)}
		</div>
	)
}

export default ExperienceProgress
