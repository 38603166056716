import { FC } from 'react'
import { FieldValues, useForm } from 'react-hook-form'

import styles from './HistoryFilters.module.sass'
import { IHistoryFilters } from './history-filters.interface'
import { MobileFilterHistory } from './mobile'
import { ReactComponent as DashIcon } from '@/Assets/svg/dash-icon.svg'
import { useIsMobile } from '@/shared/hooks/useIsMobile'
import { IHistoryFiltersForm } from '@/shared/types/global.types'
import {
	Button,
	CalendarInput,
	FormWrapper,
	ReactSelect
} from '@/shared/ui/form'
import { getMessageLocality } from '@/shared/utils'

const selectOptions = [
	{ label: getMessageLocality('show_all'), value: 'show_all' },
	{ label: getMessageLocality('only_deposits'), value: 'only_deposits' },
	{ label: getMessageLocality('only_witdrawals'), value: 'only_witdrawals' },
	{ label: getMessageLocality('status_win'), value: 'status_win' },
	{ label: getMessageLocality('status_loss'), value: 'status_loss' }
]

const HistoryFilters: FC<IHistoryFilters> = ({ setHistoryFilters }) => {
	const methods = useForm<IHistoryFiltersForm>({
		defaultValues: {
			show: selectOptions[0].value
		}
	})

	const { isMobile } = useIsMobile(1000)
	const { watch } = methods

	const onClickClear = () => {
		methods.reset()
		setHistoryFilters(null)
	}

	const onSubmit = (data: FieldValues) => {
		const res = data as IHistoryFiltersForm
		setHistoryFilters(res)
	}

	if (isMobile) {
		return (
			<div className={styles.mobiWrapper}>
				<MobileFilterHistory
					options={selectOptions}
					setHistoryFilters={setHistoryFilters}
				/>
			</div>
		)
	}

	return (
		<FormWrapper
			methods={methods}
			onSubmit={onSubmit}
			className={styles.filters}
		>
			<div className={styles.calendarsContainer}>
				<CalendarInput
					name='fromDate'
					connect
					placeholder='dd/MM/dddd'
					isMini
					selectsStart
					maxDate={watch('toDate')}
					startDate={watch('fromDate')}
					selected={watch('fromDate')}
					endDate={watch('toDate')}
				/>

				<DashIcon />
				<CalendarInput
					name='toDate'
					connect
					isMini
					placeholder='dd/MM/dddd'
					selectsEnd
					startDate={watch('fromDate')}
					endDate={watch('toDate')}
					selected={watch('toDate')}
					minDate={watch('fromDate')}
				/>
			</div>
			<ReactSelect
				name='show'
				connect
				options={selectOptions}
				placeholder=''
				containerClassName={styles.select}
			/>

			<Button
				classNameContainer={styles.button}
				title={getMessageLocality('apply')}
				modifiers={['gold', 'small']}
				type='submit'
			/>

			<Button
				classNameContainer={styles.button}
				onClick={onClickClear}
				title={getMessageLocality('clear')}
				modifiers={['gold', 'small', 'inverse']}
				type='button'
			/>
		</FormWrapper>
	)
}

export default HistoryFilters
