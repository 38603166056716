import { Dispatch } from 'redux'

export const setLang =
	(lang: string, isNotReload?: boolean) => (dispatch: Dispatch) => {
		localStorage.setItem('locale', lang)
		dispatch({
			type: 'SET_LOCALE',
			value: lang
		})
		// @ts-ignore
		document.getElementById('html').setAttribute('lang', lang)
		if (!isNotReload) {
			window.location.reload()
		}
	}

export const setLoader = (state: boolean) => (dispatch: Dispatch) => {
	dispatch({
		type: 'SET_LOADER',
		value: state
	})
}
