import clsx from 'clsx'
import { FC } from 'react'

import styles from './ReferralBlockInfo.module.scss'

interface IProps {
	count: number
	name: string
	description: string
	isLink?: boolean
	icon?: JSX.Element
	onClick: () => void
}
export const ReferralBlockInfo: FC<IProps> = ({
	count,
	description,
	icon,
	name,
	isLink,
	onClick
}) => {
	return (
		<div className={styles.item}>
			<div className={styles.number}>{count}</div>
			<div className={styles.info}>
				<div className={styles.name}>{name}</div>
				<div className={styles.description}>
					<span className={clsx(isLink && styles.link)}>{description}</span>

					{icon && (
						<div className={styles.icon} onClick={onClick}>
							{icon}
						</div>
					)}
				</div>
			</div>
		</div>
	)
}
