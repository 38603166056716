import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { LinkItemMenuProps } from '../types/global.types'

/*
 * @param {Array<LinkItemMenuProps>} inData - Массив данных меню.
 * @param {function} outData - Функция для обновления состояния меню.
 * @return {void} - Функция не возвращает значение.
 */

export function usePageViews(inData: Array<LinkItemMenuProps>, outData: any) {
	let location = useLocation()
	useEffect(() => {
		inData.find((tab, index) => {
			if (location.pathname.search(tab.link) !== -1) {
				outData((prevState: Array<LinkItemMenuProps>) => {
					inData.find((item, index_find) => {
						if (item.itemMenu.active) {
							prevState[index_find].itemMenu.active = false
							return true
						} else return false
					})
					prevState[index].itemMenu.active = true
					return [...prevState]
				})
				return true
			} else {
				outData((prevState: Array<LinkItemMenuProps>) => {
					inData.find((item, index_find) => {
						if (item.itemMenu.active) {
							prevState[index_find].itemMenu.active = false
							return true
						} else return false
					})
					return [...prevState]
				})
				return false
			}
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location])
}
