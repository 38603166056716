import { FC, useState } from 'react'

import styles from './EditProfileContent.module.scss'
import { IDesktopEditProfile } from './edit-profile-content.interface'
import EarnedDailyRewardModal from '@/entities/profile/earned-daily-reward-modal/EarnedDailyRewardModal'
import CardLevelInfo from '@/features/profile/card-level-info'
import UserData from '@/features/profile/user-data/ui'
import { useIsMobile } from '@/shared/hooks/useIsMobile'

const EditProfileContent: FC<IDesktopEditProfile> = ({ userData }) => {
	const [isModalOpened, setIsModalOpened] = useState(false)
	const { isMobile } = useIsMobile(768)

	return (
		<div className={styles.wrapper}>
			<UserData
				userData={userData}
				setIsModalOpened={setIsModalOpened}
				isModalOpened={isModalOpened}
				isVisibleButton={!isMobile}
				isHiddenInfo={isMobile}
			/>

			<CardLevelInfo
				userData={userData}
				levelName={userData?.star?.raiting_name_en}
				setIsModalOpened={setIsModalOpened}
			/>

			{isModalOpened && (
				<EarnedDailyRewardModal setIsModalOpened={setIsModalOpened} />
			)}
		</div>
	)
}

export default EditProfileContent
