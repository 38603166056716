import { FC } from 'react'

import styles from './Error.module.scss'
import { getMessageLocality } from '@/shared/utils'

interface IProps {
	message: string
}

const Error: FC<IProps> = () => {
	return (
		<div className={styles.wrapper}>
			<div className={styles.image}>
				<img src='' alt='' />
			</div>
			<div className={styles.message}>{getMessageLocality('error_page')}</div>
		</div>
	)
}

export default Error
