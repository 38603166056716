import clsx from 'clsx'
import React, { FC, PropsWithChildren, ReactNode } from 'react'

import styles from './quiz-content.module.scss'
import { Button } from '@/shared/ui/form'
import { getMessageLocality } from '@/shared/utils'

interface IProps extends PropsWithChildren {
	title?: string
	classNameWrapper?: string
	classNameButton?: string
	maxWidth?: string
	buttonsJSX?: ReactNode
	onFinal?: () => void
	onNextStep?: () => void
}

export const QuizContent: FC<IProps> = ({
	title,
	classNameWrapper,
	children,
	maxWidth,
	onFinal,
	onNextStep,
	classNameButton,
	buttonsJSX
}) => {
	return (
		<div
			className={clsx(styles.wrapper, classNameWrapper)}
			style={{ maxWidth }}
		>
			<div
				className={clsx(
					styles.content,
					'animate__animated animate__backInRight'
				)}
			>
				{title && <h2 className='title'>{title}</h2>}

				{children}
			</div>
			{onFinal ? (
				<div
					className={clsx(
						styles.buttonCollect,
						'animate__animated animate__bounce'
					)}
				>
					<Button
						classNameContainer={classNameButton}
						title={getMessageLocality('collect')}
						modifiers={['gold']}
						onClick={() => onFinal?.()}
					/>
				</div>
			) : buttonsJSX ? (
				<>
					<div className={clsx(styles.buttons)}>{buttonsJSX}</div>
				</>
			) : (
				<div className={clsx(styles.buttons)}>
					<Button
						title={getMessageLocality('yes')}
						modifiers={['gold']}
						onClick={() => onNextStep?.()}
					/>
					<Button
						title={getMessageLocality('no')}
						modifiers={['inverse']}
						onClick={() => onNextStep?.()}
					/>
				</div>
			)}
		</div>
	)
}
