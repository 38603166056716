import { useParams } from 'react-router-dom'

import { useGetExpertDetail } from '@/features/expert/api'
import { NotFound } from '@/pages/not-found'
import { useTranslationField } from '@/shared/hooks/useTranslationField'
import Layout from '@/shared/ui/layout'
import { Loader } from '@/shared/ui/loader/Loader'
import { SuspenseWrapper } from '@/shared/ui/suspense'
import { getMessageLocality } from '@/shared/utils'
import { ExpertDetailsWidget } from '@/widgets/experts/[id]'

const ExpertDetail = () => {
	const { getField } = useTranslationField()

	const { id } = useParams()

	const { data, isLoading, error } = useGetExpertDetail(Number(id))

	//@ts-ignore
	const isNeedMoreRating = error?.response?.data?.detail?.includes(
		'Need more rating points'
	)

	const titleLang =
		getField(data, 'name') || (!isLoading && getMessageLocality('not_found'))
	const shortLang = getField(data, 'description_cn')

	if (!isNeedMoreRating && error) return <NotFound />

	return (
		<SuspenseWrapper>
			<Layout
				title={titleLang}
				description={shortLang}
				titlePage={titleLang}
				descriptionMeta={shortLang}
				fullWidth={false}
				position='left'
				menuName={titleLang}
				extraMenuBlock={{
					titleSection: titleLang
				}}
				titlePosition={'left'}
			>
				<div style={{ position: 'relative' }}>
					{isLoading ? (
						<Loader absolute />
					) : data ? (
						<ExpertDetailsWidget data={data} />
					) : isNeedMoreRating ? (
						<div
							style={{ textAlign: 'center', fontSize: 18, marginTop: '2rem' }}
						>
							{getMessageLocality('expertMoreExperience')}
						</div>
					) : null}
				</div>
			</Layout>
		</SuspenseWrapper>
	)
}

export default ExpertDetail
