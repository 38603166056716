import { FC, PropsWithChildren, Suspense } from 'react'

import { Loader } from '../loader/Loader'

import { useForwardPostBack } from '@/app/hooks'
import ErrorBoundary from '@/app/providers/error-boundary'

export const SuspenseWrapper: FC<PropsWithChildren> = ({ children }) => {
	useForwardPostBack()

	return (
		<Suspense fallback={<Loader />}>
			<ErrorBoundary>{children}</ErrorBoundary>
		</Suspense>
	)
}
