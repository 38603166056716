import cn from 'clsx'
import { FC, useEffect, useState } from 'react'

import styles from './PaymentHistory.module.sass'
import { usePaymentHistory } from './usePaymentHistory'
import HistoryFilters from '@/features/profile/history-filters'
import PaymentHistoryDetail from '@/features/profile/payment-history-detail'
import PaymentHistoryTable from '@/features/profile/payment-history-table'
import { IHistoryFiltersForm } from '@/shared/types/global.types'
import { Loader } from '@/shared/ui/loader/Loader'

const PaymentHistory: FC = () => {
	const [historyFilters, setHistoryFilters] =
		useState<IHistoryFiltersForm | null>(null)

	const {
		isPaymentHistoryLoading,
		isFetching,
		isFetchingNextPage,
		paymentHistory,
		hasNextPage,
		fetchNextPage
	} = usePaymentHistory(historyFilters)

	useEffect(() => {
		const onScroll = (event: any) => {
			const { scrollHeight, scrollTop, clientHeight } = event?.target
			if (!isFetchingNextPage && scrollHeight - scrollTop <= 2 * clientHeight) {
				if (paymentHistory.total_count > paymentHistory.items.length)
					fetchNextPage()
			}
		}

		const app = document.getElementById('App')
		app?.addEventListener('scroll', onScroll)
		return () => app?.removeEventListener('scroll', onScroll)
	}, [isFetching, fetchNextPage, hasNextPage, isFetchingNextPage])

	const [paymentDetailModal, setPaymentDetailModal] = useState({
		isOpen: false,
		itemId: 0
	})

	useEffect(() => {
		if (paymentDetailModal.isOpen) {
			document.body.style.overflow = 'hidden'
		} else {
			document.body.style.overflow = 'auto'
		}

		return () => {
			document.body.style.overflow = 'auto'
		}
	}, [paymentDetailModal.isOpen])

	const paymentHistoryDetailItem = paymentHistory?.items.find(
		item => item.id === paymentDetailModal.itemId
	)

	
	return (
		<div
			className={cn(styles.container, {
				[styles.modalContainer]: false
			})}
		>
			{/* <HistoryFilters setHistoryFilters={setHistoryFilters} /> */}
			{isPaymentHistoryLoading ? (
				<Loader />
			) : (
				<PaymentHistoryTable
					paymentHistoryItems={paymentHistory?.items}
					isOpenModal={paymentDetailModal.isOpen}
					setPaymentDetailModal={setPaymentDetailModal}
				/>
			)}
			{paymentDetailModal.isOpen && (
				<PaymentHistoryDetail
					paymentItem={paymentHistoryDetailItem}
					setPaymentDetailModal={setPaymentDetailModal}
				/>
			)}
		</div>
	)
}

export default PaymentHistory
