import { AlertTemplateProps } from 'react-alert'
import { FormattedMessage } from 'react-intl'

import { IconInfo } from './AlertSvg'
import { messages } from '@/i18n/messages'
import { useAppSelector } from '@/shared/model'
import { useProfileData } from '@/features/profile/api/useProfileData'

// Компонент алерта
export const AlertTemplate: (props: AlertTemplateProps) => JSX.Element = ({
	style,
	options,
	message,
	close
}) => {
	const { locale } = useAppSelector(state => state.defaultReducer)
	const { userData } = useProfileData()

	return (
		<div style={style} className='alert'>
			{options.type === 'info' ? (
				<IconInfo message={messages[locale].information} />
			) : options.type === 'success' ? (
				<IconInfo message={messages[locale].success} />
			) : options.type === 'error' ? (
				<IconInfo message={messages[locale].error} />
			) : null}
			{typeof message === 'string' && (
				<>
					<FormattedMessage id={message} />{' '}
					{message === 'message_login_success' ? `${userData?.name}!` : ''}
				</>
			)}
			<button className='primaryButton' onClick={close}>
				{messages[locale].close}
			</button>
		</div>
	)
}
