import { useInfiniteQuery } from '@tanstack/react-query'
import { useEffect, useState } from 'react'

import UserService from '@/shared/services/user.service'
import {
	IHistoryFiltersForm,
	IPaginationData
} from '@/shared/types/global.types'
import { IPaymentHistory } from '@/shared/types/user.types'

// export const usePaymentHistory = (
// 	historyFilters: IHistoryFiltersForm | null
// ) => {
// 	// console.log(historyFilters)
//
// 	const { isLoading: isPaymentHistoryLoading, data: paymentHistory } = useQuery(
// 		['get buys list', historyFilters],
// 		() => UserService.getBuysList(historyFilters || {}),
// 		{ select: ({ data }) => data }
// 	)
//
// 	return { isPaymentHistoryLoading, paymentHistory }
// }

export const usePaymentHistory = (
	historyFilters: IHistoryFiltersForm | null
) => {
	const [paymentHistory, setPaymentHistory] = useState<IPaymentHistory>({
		total_count: 0,
		items: []
	})

	const allHistoryData = {
		page: 1,
		count: 21
	} as IPaginationData

	const {
		isLoading: isPaymentHistoryLoading,
		isFetching,
		isFetchingNextPage,
		data: paymentHistoryPages,
		hasNextPage,
		fetchNextPage
	} = useInfiniteQuery(
		['get buys list', historyFilters],
		({ pageParam = 1 }) => {
			allHistoryData.page = pageParam
			return UserService.getBuysList(historyFilters || {})
		},
		{
			getNextPageParam: (_lastPage, pages) => pages.length + 1
			// keepPreviousData: true
		}
	)

	useEffect(() => {
		let newHistory: IPaymentHistory = {
			total_count: 0,
			items: []
		}
		paymentHistoryPages?.pages.forEach(item => {
			newHistory.items.push(...item.data.items)
			newHistory.total_count = item.data.total_count
		})
		setPaymentHistory(newHistory)
	}, [paymentHistoryPages])

	return {
		isPaymentHistoryLoading,
		isFetching,
		isFetchingNextPage,
		paymentHistory,
		hasNextPage,
		fetchNextPage
	}
}
