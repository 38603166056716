import { FC } from 'react'
import { FormattedMessage } from 'react-intl'

import styles from './BettingHistoryTable.module.sass'
import { IBettingHistoryTable } from './betting-history-table.interface'
import TableItem from './item'
import { ItemMobile } from './item-mobile'
import { useIsMobile } from '@/shared/hooks/useIsMobile'
import { getMessageLocality } from '@/shared/utils'

const BettingHistoryTable: FC<IBettingHistoryTable> = ({ bettingHistory }) => {
	const { isMobile } = useIsMobile(1000)
	return (
		<>
			<div className={styles.wrapper}>
				{isMobile && (
					<div className={styles.list}>
						{bettingHistory?.length ? (
							bettingHistory.map(bet => <ItemMobile key={bet.id} bet={bet} />)
						) : (
							<p className={styles.notFount} style={{ color: 'whitwe' }}>
								{getMessageLocality('not_found')}
							</p>
						)}
					</div>
				)}
			</div>

			{!isMobile && (
				<div className={styles.tableBody}>
					{bettingHistory?.length ? (
						bettingHistory.map(bet => <TableItem key={bet.id} bet={bet} />)
					) : (
						<p className={styles.notFount} style={{ color: 'whitwe' }}>
							{getMessageLocality('not_found')}
						</p>
					)}
				</div>
			)}
		</>
	)
}

export default BettingHistoryTable
