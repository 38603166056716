import { useInfiniteQuery } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'

import AnalyticsService from '@/shared/services/analytics.service'
import { IAnalytic } from '@/shared/types/analytics.types'
import { sortDataInfinityPages } from '@/shared/utils'

export const useAnalyticsList = (count: number, authorName?: string) => {
	const [analyticsData, setAnalyticsData] = useState<
		IAnalytic.DataAnalytic[] | null
	>(null)

	const { ref, inView } = useInView({
		threshold: 0
	})

	const {
		data,
		isFetching,
		isLoading,
		isFetchingNextPage,
		hasNextPage,
		fetchNextPage
	} = useInfiniteQuery(
		['analytics list', count],
		({ pageParam = 1 }) =>
			AnalyticsService.getAll({
				count: count,
				page: pageParam,
				author_name: authorName
			}),
		{
			getNextPageParam: (lastPage, pages) => {
				if (!lastPage.data.length || lastPage.data.length < count) return null
				return pages.length + 1
			},
			select: data => {
				const res = data.pages.map(item => item.data)
				return { ...data, pages: res }
			}
		}
	)

	useEffect(() => {
		const result = sortDataInfinityPages(data)

		if (!isLoading) setAnalyticsData(result)
	}, [data?.pages])

	useEffect(() => {
		if (inView && hasNextPage && !isFetchingNextPage) {
			fetchNextPage()
		}
	})

	return {
		analyticsData,
		isFetching,
		isLoading,
		isFetchingNextPage,
		hasNextPage,
		fetchNextPage,
		refAnalytic: ref
	}
}
