import clsx from 'clsx'
import { FC } from 'react'
import { useNavigate } from 'react-router-dom'

import styles from './ExpertCard.module.scss'
import { BlockedIconJSX } from '@/shared/assets/common/svg'
import { experts_1 } from '@/shared/assets/experts/img'
import { ExpertsBgJsx } from '@/shared/assets/experts/svg'
import { useTranslationField } from '@/shared/hooks'
import { IAnalytic } from '@/shared/types/analytics.types'
import { Button } from '@/shared/ui/form'
import { getMessageLocality } from '@/shared/utils'

interface IProps {
	isBlock?: boolean
	textBlocked?: string
	data: IAnalytic.ExpertData
	toLink: string
}

export const ExpertCard: FC<IProps> = ({
	isBlock,
	textBlocked,
	data,
	toLink
}) => {
	const { getField } = useTranslationField()

	const navigation = useNavigate()
	const onClick = () => {
		if (isBlock) return
		navigation(toLink)
	}
	return (
		<div
			className={clsx(styles.wrapper, isBlock && styles.blocked)}
			onClick={onClick}
		>
			<div className={styles.images}>
				<div className={styles.bgImages}>
					<ExpertsBgJsx />
				</div>
				<img src={data.img_link} alt={data.name_en} />
			</div>
			<div className={styles.infoBlock}>
				<div className={styles.info}>
					<div className={styles.title}>{getField(data, 'name')}</div>
					<div className={styles.description}>{getField(data, 'category')}</div>
					<div className={styles.button}>
						<Button
							modifiers={['inverse']}
							title={getMessageLocality('information')}
						/>
					</div>
				</div>
			</div>

			{isBlock && (
				<div className={styles.blockWrapper}>
					<div className={styles.block} />
					<div className={styles.blockIcon}>
						<BlockedIconJSX />
						<div className={styles.blockText}>
							{getMessageLocality('blocked')}
						</div>
						<div className={styles.unBlockText}>
							{getMessageLocality('required_level_start')} {textBlocked}
							{getMessageLocality('required_level')}
						</div>
					</div>
				</div>
			)}
		</div>
	)
}
