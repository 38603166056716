/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { FieldValues, useForm } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'
import { useNavigate } from 'react-router-dom'

// Элементы
// Экшны
import '@/Assets/sass/components/Auth.sass'
import { ReactComponent as CloseIcon } from '@/Assets/svg/Auth/close-icon.svg'
import { ReactComponent as LoginIconCn } from '@/Assets/svg/Auth/login-icon-cn.svg'
import { ReactComponent as LoginIconEn } from '@/Assets/svg/Auth/login-icon-en.svg'
import { TStepAuth } from '@/app/providers/protected-router'
import { messages } from '@/i18n/messages'
import { useErrorAlert } from '@/shared/hooks/useErrorAlert'
import { useAppDispatch, useAppSelector } from '@/shared/model'
import { validEmail } from '@/shared/regex'
import { login } from '@/shared/store/actions/auth'
import { FormWrapper, Input } from '@/shared/ui/form'
import { Loader } from '@/shared/ui/loader/Loader'

interface IProps {
	page: (val: TStepAuth) => void
	widget?: boolean
}
export const Login: FC<IProps> = ({ page, widget = false }) => {
	const formLogin = useForm({ mode: 'onBlur' })
	const {
		formState: { errors }
	} = formLogin

	const { loader, locale } = useAppSelector(state => state.defaultReducer)

	const dispatch = useAppDispatch()

	const navigate = useNavigate()

	const onLogin = (values: FieldValues) => {
		const form = new FormData()
		for (const key in values) {
			form.append(key, values[key])
		}

		dispatch(login(form))
			.then(() => {})
			.catch(error => {})

		formLogin.reset({ email: '', password: '' })
	}

	if (loader) return <Loader />
	return (
		<div className='FormBlock'>
			<FormWrapper methods={formLogin} onSubmit={onLogin} className='LoginForm'>
				{locale === 'en' ? <LoginIconEn /> : <LoginIconCn />}
				<div className='formGroup'>
					<div
						className='close'
						onClick={() => (widget ? page('') : navigate(-1))}
					>
						<CloseIcon />
					</div>
					<div className='title'>
						<FormattedMessage id='login' />
					</div>
					<div>
						<FormattedMessage id='login_email'>
							{message => (
								<Input
									mask=''
									name='email'
									type='email'
									connect
									placeholder={message.toString()}
									style={{ marginTop: '-5px' }}
									errorText={errors?.email?.message}
									registerConfig={{
										required: messages[locale].required_field,
										pattern: {
											value: validEmail,
											message: messages[locale].correct_email
										}
									}}
								/>
							)}
						</FormattedMessage>
					</div>
					<div className='inputPass'>
						<FormattedMessage id='login_pass'>
							{message => (
								<Input
									mask=''
									name='password'
									type='password'
									connect
									isPassword
									placeholder={message.toString()}
									errorText={errors?.password?.message}
									registerConfig={{
										required: messages[locale].required_field,
										minLength: {
											value: 8,
											message: messages[locale].minimum_characters + ' - 8'
										}
									}}
								/>
							)}
						</FormattedMessage>
					</div>
					<button
						className='buttonRedHoverBlackBorderGold'
						type='submit'
						data-content={messages[locale].login}
					></button>
					<span onClick={() => page('registration')}>
						<FormattedMessage id='register' />
					</span>
					<span
						onClick={() => page('forgot-password')}
						style={{ marginBottom: '30px' }}
					>
						<FormattedMessage id='forgot_pass' />
					</span>
				</div>
			</FormWrapper>
		</div>
	)
}

export default Login
