import clsx from 'clsx'
import React, { FC, useEffect, useRef, useState } from 'react'
import Truncate from 'react-truncate'

import styles from './collapsible.module.scss'
import { getMessageLocality } from '@/shared/utils'

interface IProps {
	countString?: number
	className?: number
	text: string
}
const CollapsibleText: FC<IProps> = ({
	text = '',
	countString = 2,
	className
}) => {
	const [isCollapsed, setIsCollapsed] = useState(true)

	const toggleCollapse = () => {
		setIsCollapsed(!isCollapsed)
	}

	return (
		<div className={clsx(styles.wrapper, className)}>
			<Truncate
				lines={!isCollapsed ? false : countString}
				ellipsis={
					<span>
						...{'   '}
						<span className={styles.open} onClick={toggleCollapse}>
							{getMessageLocality('unRoll')}
						</span>
					</span>
				}
			>
				<div dangerouslySetInnerHTML={{ __html: text }} />
			</Truncate>

			{!isCollapsed && (
				<span className={styles.close} onClick={toggleCollapse}>
					{getMessageLocality('roll')}
				</span>
			)}
		</div>
	)
}

export default CollapsibleText
