import cn from 'clsx'
import { FC, useState } from 'react'

import styles from '../BettingHistoryTable.module.sass'
import { IBettingHistoryTableItem } from '../betting-history-table.interface'

import { useStatusBet } from './useStatusBet'
import { ReactComponent as ArrowIcon } from '@/Assets/svg/arrow-select.svg'
import BettingCoefficient from '@/entities/profile/betting-coefficient'
import { useTranslationField } from '@/shared/hooks/useTranslationField'
import { timeFormatter } from '@/shared/utils/timeFormatter'
import { getMessageLocality, reFormatNum } from '@/shared/utils'

const TableItem: FC<IBettingHistoryTableItem> = ({ bet }) => {
	const { getField } = useTranslationField()

	const { getBetMessage, getBetMessageExpress, getBetStatus, getStatus } =
		useStatusBet()

	const [isMoreInfoOpen, setIsMoreInfoOpen] = useState(false)

	const isExpress = Array.isArray(bet.game_list)

	const onClickTableItem = () => {
		if (!isExpress) return
		setIsMoreInfoOpen(prevState => !prevState)
	}

	//@ts-ignore
	const gameInfo = isExpress ? bet.game_list[0] : bet.game_list

	return (
		<div
			onClick={onClickTableItem}
			className={cn(styles.tableItemContainer, {
				[styles.pointer]: isExpress,
				[styles.active]: isMoreInfoOpen
			})}
		>
			<button type='button' className={styles.tableItem}>
				<div>
					<h4>#{bet.id}</h4>
					<p>{timeFormatter(bet.createdAt)}</p>
				</div>
				<div>
					{isExpress ? (
						<div
							style={{
								display: 'flex',
								// justifyContent: 'space-between',
								gap: '10px',
								minHeight: '40px'
							}}
						>
							<h4>{getMessageLocality('express_game_title')}</h4>

							<ArrowIcon
								className={cn({
									[styles.rotated]: isMoreInfoOpen,
									[styles.icon]: true
								})}
							/>
						</div>
					) : (
						<>
							<h4>{getField(gameInfo, 'tournament_name')}</h4>
							<p>
								{getField(gameInfo, 'opponent_one_name')}
								&nbsp;-&nbsp;
								{getField(gameInfo, 'opponent_two_name')}
							</p>
							<p>{timeFormatter(gameInfo.start_date)}</p>
						</>
					)}
				</div>
				<div>
					<h4>{getMessageLocality('bet')}</h4>
					<p className={styles[getStatus(bet)]}>{reFormatNum(bet.amount)} ₿</p>
				</div>
				<div>
					<h4>{getMessageLocality(bet.isWin ? 'win' : 'possible_winnings')}</h4>

					<p>
						{bet.isWin
							? reFormatNum(bet.final_result || bet.possible_win)
							: reFormatNum(bet.possible_win)}{' '}
						₿
					</p>
				</div>
				<div>
					<h4>{getMessageLocality('status')}</h4>
					<p className={styles[getStatus(bet)]}>{getBetMessage(bet)}</p>
				</div>
				<div>
					<h4>{getMessageLocality('bet_types')}</h4>
					<p>{getMessageLocality(bet.type_of_bet)}</p>
				</div>
				<div className={styles.info}>
					<BettingCoefficient coeff={+bet.total_kef} status={getStatus(bet)} />
					{isExpress && (
						<ArrowIcon className={cn({ [styles.rotated]: isMoreInfoOpen })} />
					)}
				</div>
			</button>
			{Array.isArray(bet.game_list) &&
				bet.game_list.map((item, i) => {
					const betMessage = getBetMessageExpress(item)
					const status = getBetStatus(item)

					return (
						<div key={item.id} className={styles.moreInfo}>
							<>
								<div></div>
								<div>
									<h4>{getField(item, 'tournament_name')}</h4>
									<p>
										{getField(item, 'opponent_one_name')}&nbsp;-&nbsp;
										{getField(item, 'opponent_two_name')}
									</p>
									<p>{timeFormatter(item.start_date).replace(',', ' |')}</p>
								</div>
								<div>
									<h4>{getMessageLocality('result')}</h4>
									<p>{item.score || '-'}</p>
								</div>
								<div>
									<h4>{getMessageLocality('event')}</h4>
									<p>{getField(item.markets, 'name')}</p>
								</div>
								<div>
									<h4>{getMessageLocality('event_status')}</h4>

									<p className={styles[status]}>{betMessage}</p>
								</div>
								<BettingCoefficient
									coeff={+item.markets.market}
									status={status}
									className={styles.coeff}
								/>
							</>
						</div>
					)
				})}
		</div>
	)
}

export default TableItem
