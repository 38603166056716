import clsx from 'clsx'
import { FC } from 'react'
import { useSearchParams } from 'react-router-dom'

import { BettingExperts } from '../slices/betting-history'

import styles from './ExpertDetail.module.scss'
import { EXPERT_SPORTS_NAME } from './contants'
import { ExpertDetailInfo } from '@/entities/experts'
import { ExpertForCast } from '@/features/expert/ui'
import { useIsMobile, useTranslationField } from '@/shared/hooks'
import { IAnalytic } from '@/shared/types/analytics.types'
import { LayoutTabs } from '@/shared/ui/layout-tabs'
import { getMessageLocality } from '@/shared/utils'

interface IProps {
	data: IAnalytic.ExpertData
}
export const ExpertDetailsWidget: FC<IProps> = ({ data }) => {
	const { isMobile } = useIsMobile()
	const [searchParams] = useSearchParams()
	const { getField } = useTranslationField()

	const isTab = !!searchParams.get('tab')
	const findNameExperts = EXPERT_SPORTS_NAME?.find(item =>
		data?.category_en?.includes(item.name)
	)

	const sportName =
		findNameExperts?.sportName || findNameExperts?.sportName === null
			? findNameExperts?.sportName
			: 'anyway'

	return (
		<div className={styles.wrapper}>
			{((!isTab && isMobile) || !isMobile) && (
				<ExpertDetailInfo
					title={getField(data, 'name')}
					category={getField(data, 'category')}
					description={getField(data, 'description')}
					imgLink={data.img_link}
				/>
			)}
			<div
				className={clsx(
					styles.tabs,
					isMobile && styles.isMobile,
					isMobile && !isTab && styles.isTopMobile
				)}
			>
				<LayoutTabs
					isMobile={isMobile}
					tabsData={[
						{ label: getMessageLocality('forCasts'), name: 'forCasts' },
						{
							label: getMessageLocality('betting-history_route'),
							name: 'betting-history'
						}
					]}
				>
					<ExpertForCast authorName={data.name_en} idExpert={data.id} />
					<BettingExperts sportName={sportName} />
				</LayoutTabs>
			</div>
		</div>
	)
}
