import { QuizLayout } from 'features/quiz'
import { FC } from 'react'

import { TQuiz } from '../..'

import { backgroundQuiz_52, background_5_mobi, bubble_52, image_52 } from '@/Assets/img/quiz'
import { useIsMobile } from '@/shared/hooks/useIsMobile'
import { QuizLayoutFour } from '@/shared/ui/quiz-layout/quiz-layoyt-4'
import { getMessageLocality } from '@/shared/utils'

export const QuizFour: FC<TQuiz> = ({ onFinal }) => {
	const { isMobile } = useIsMobile(768)

	return (
		<QuizLayout
			background={bubble_52}
			isFinal
			extraImage={isMobile ? background_5_mobi : backgroundQuiz_52}
		>
			<QuizLayoutFour
				image={image_52}
				descriptions={[
					getMessageLocality('finalTextVariant1'),
					getMessageLocality('finalText2Variant1'),
					getMessageLocality('finalText3Variant1')
				]}
				onFinal={onFinal}
			/>
		</QuizLayout>
	)
}
