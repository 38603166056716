import cn from 'clsx'
import { FC } from 'react'

import styles from './TabButton.module.sass'
import { ITabButton } from './tab-button.interface'
import { useLocaleTranslation } from '@/shared/hooks/useLocaleTranslation'

const TabButton: FC<ITabButton> = ({
	tabData,
	icon,
	active,
	name,
	...rest
}) => {
	const label = useLocaleTranslation(tabData || {}, 'name') || name

	return (
		<button
			type='button'
			className={cn(styles.tabButton, { [styles.active]: active })}
			{...rest}
		>
			{icon && <img src={icon} alt='icon' />}
			<span>{label}</span>
		</button>
	)
}

export default TabButton
