import EarnedDailyRewardModal from '@/entities/earned-daily-reward-modal'
import { useState } from 'react'

import { QuizLang } from '../slices/quiz_lang'

import { QuizOne } from './quiz_1'
import { QuizTwo } from './quiz_2'
import { QuizThree } from './quiz_3'
import { QuizFour } from './quiz_4'
import { useLocalStorage } from '@/shared/hooks/useLocalStorage'
import { FINAL_QUIZ } from '@/shared/constants'
import { useNavigate } from 'react-router-dom'

const quizzes = [QuizLang, QuizOne, QuizTwo, QuizThree, QuizFour]

export const QuizVariant2 = () => {
	const navigate = useNavigate()
	const { setLocalStorageValue } = useLocalStorage(FINAL_QUIZ)
	const [isFinal, setIsFinal] = useState(false)
	const [step, setStep] = useState(0)
	const [isModalOpened, setIsModalOpened] = useState(false)

	const onNextStep = () => {
		setStep(prev => prev + 1)
	}

	const onFinalStep = () => {
		if (isFinal) return
		setIsModalOpened(true)
		setIsFinal(true)
	}

	const onCloseModal = () => {
		setLocalStorageValue('on')
		navigate('/profile?tab=account-profile')
		setIsModalOpened(false)
	}

	const CurrentQuiz = quizzes[step]
	return (
		<>
			<CurrentQuiz onNextStep={onNextStep} onFinal={onFinalStep} />
			{isModalOpened && <EarnedDailyRewardModal onClose={onCloseModal} />}
		</>
	)
}
