import { useInfiniteQuery } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'

import UserService from '@/shared/services/user.service'
import {
	IHistoryFiltersForm,
	IPaginationData
} from '@/shared/types/global.types'
import { IExpressList } from '@/shared/types/sport.types'

export const useBettingExpert = (
	historyFilters: IHistoryFiltersForm | null,
	sportName?: string | null
) => {
	const [prevFilters, setPrevFilters] = useState(historyFilters)

	const { ref, inView } = useInView({
		threshold: 0
	})
	const [bettingHistory, setListBettingHistory] = useState<IExpressList[]>([])

	let allHistoryData = {
		page: 1,
		count: 21
	} as IPaginationData
	const {
		isLoading,
		isFetching,
		isFetchingNextPage,
		data: bettingHistoryPages,
		hasNextPage,
		fetchNextPage
	} = useInfiniteQuery(
		['get all tournaments', historyFilters, sportName],
		({ pageParam = 1 }) => {
			allHistoryData.page = pageParam
			return UserService.getBetsExpertsList(
				{ ...allHistoryData, ...historyFilters, sport_name: sportName } || {}
			)
		},
		{
			retry: 0,
			getNextPageParam: (_lastPage, pages) => {
				if (JSON.stringify(historyFilters) !== JSON.stringify(prevFilters)) {
					return 1
				}
				return pages.length + 1
			}
		}
	)

	//TODO мб удалить
	useEffect(() => {
		setPrevFilters(historyFilters)
		setListBettingHistory([])
	}, [historyFilters])

	const isEmpty = bettingHistoryPages?.pages.at(-1)?.data?.length === 0

	useEffect(() => {
		let newHistory: Array<IExpressList> = []
		bettingHistoryPages?.pages.map(item => newHistory.push(...item.data))
		setListBettingHistory(newHistory)
	}, [bettingHistoryPages])

	useEffect(() => {
		if (inView && hasNextPage && !isFetchingNextPage && !isEmpty) {
			fetchNextPage()
		}
	})
	return {
		isLoading,
		isFetching,
		isEmpty,
		isFetchingNextPage,
		bettingHistory,
		hasNextPage,
		fetchNextPage,
		refBetting: ref
	}
}
