import { FC, useEffect, useMemo, useState } from 'react'

import styles from './Experts.module.scss'
import { Tabs } from './slices'
import { ExpertCard } from '@/entities/experts'
import { useGetExperts } from '@/features/expert/api'
import { useProfileData } from '@/features/profile/api'
import { useGetRating } from '@/features/reward-system/api'
import { useButtonUpScroll, useTranslationField } from '@/shared/hooks'
import { Loader } from '@/shared/ui/loader/Loader'
import { getMessageLocality } from '@/shared/utils'

const ExpertsWidget: FC = () => {
	const { data, isLoading } = useGetRating({ count: 4 })
	const { userData } = useProfileData()
	const [currentTab, setCurrentTab] = useState(0)
	const { getField } = useTranslationField()
	const rattingUser = userData?.rating!

	const listLvl = useMemo(() => {
		return (
			data?.map(item => ({ id: item.id, title: getField(item, 'name') })) || []
		)
	}, [data])

	const currentRatingName = useMemo(
		() => data?.find(item => item.id === currentTab)?.name_en,
		[currentTab, data]
	)

	useEffect(() => {
		if (listLvl?.length) {
			setCurrentTab(listLvl[0]?.id)
		}
	}, [listLvl])

	const {
		isFetching,
		data: dataExperts,
		isLoading: isLoadingExperts
	} = useGetExperts({
		rating_name: currentRatingName!
	})

	if (isLoading && !userData) return <Loader />

	return (
		<div className={styles.container}>
			<div className={styles.heading}>
				<h2>{getMessageLocality('experts_route')}</h2>
				<p>{getMessageLocality('experts_description')}</p>
			</div>
			<div className={styles.content}>
				<Tabs
					tabs={listLvl}
					currentTab={currentTab}
					setCurrentTab={setCurrentTab}
				/>
				<div className={styles.cardsList}>
					{(isFetching || isLoadingExperts) && <Loader absolute isMini />}
					{!isFetching &&
						!isLoadingExperts &&
						!dataExperts?.length &&
						getMessageLocality('not_found')}

					{dataExperts?.map(item => {
						const isBlocked = item.raiting_int
							? rattingUser < item.raiting_int
							: false

						if (!item.is_active) return null
						return (
							<ExpertCard
								data={item}
								toLink={`/experts/item/${item?.id}`}
								isBlock={isBlocked}
								textBlocked={currentRatingName}
							/>
						)
					})}
				</div>
			</div>
		</div>
	)
}

export default ExpertsWidget
