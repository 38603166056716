import { FC, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'

import styles from './UserData.module.scss'
import ExperienceProgress from './experience-progress'
import { IUserData } from './user-data.interface'
import { CollectButton } from '@/entities/profile/collect-button'

const UserData: FC<IUserData> = ({
	userData,
	setIsModalOpened,
	isModalOpened,
	isHiddenInfo,
	isVisibleButton
}) => {
	const formatter = new Intl.NumberFormat('ru-RU')
	const [isDisabledBonuses, setIsDisabledBonuses] = useState(
		!userData?.bonus_is_ready
	)

	const formattedBirthday =
		userData?.birthday && userData.birthday.split('-').reverse().join('.')

	const dataUserField = [
		{
			id: 'birth',
			data: formattedBirthday
		},
		{
			id: 'login_email',
			data: userData?.email
		},
		{
			id: 'phone',
			data: userData?.phone ? `+${userData.phone || ''}` : ''
		},
		{
			id: 'country',
			data: `United States of America`
		}
	]

	useEffect(() => {
		setIsDisabledBonuses(!userData?.bonus_is_ready)
	}, [userData?.bonus_is_ready])

	useEffect(() => {
		if (isModalOpened) {
			setIsDisabledBonuses(true)
		}
	}, [isModalOpened])

	const onClickCollectButton = () => {
		setIsModalOpened(true)
	}

	if (!userData) return null

	return (
		<>
			<div className={styles.card}>
				<ExperienceProgress
					rating={userData.rating}
					starData={userData.star}
					isMobile={false}
					isHiddenInfo={isHiddenInfo}
				/>
				<div className={styles.info}>
					<div className={styles.infoField}>
						<div className={styles.name}>
							<h3>{userData.name || 'Unknown'}</h3>
						</div>
						<div className={styles.balance}>
							<FormattedMessage id='balance' />:{' '}
							{formatter.format(userData.wallet)} ₿
						</div>
						{dataUserField.map(item => (
							<p className={styles.infoItem} key={item.id}>
								<span>
									<FormattedMessage id={item.id} />:
								</span>{' '}
								{item.data}
							</p>
						))}
					</div>
					{isVisibleButton && (
						<CollectButton
							onClick={onClickCollectButton}
							disabled={isDisabledBonuses}
						/>
					)}
				</div>
			</div>
		</>
	)
}

export default UserData
