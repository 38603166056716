import { countries } from 'country-list-json'
import { isBefore, isValid, parse } from 'date-fns'
import { FC, useEffect } from 'react'
import { FieldValues, UseFormReturn } from 'react-hook-form'

import { useProfileData } from '../api'

import styles from './EditDataForm.module.scss'
import { validEmail } from '@/shared/regex'
import {
	Button,
	CalendarInput,
	FormWrapper,
	Input,
	ReactSelect
} from '@/shared/ui/form'
import { getMessageLocality } from '@/shared/utils'

interface IProps {
	methods: UseFormReturn<FieldValues, any, undefined>
	onSubmit: (data: FieldValues) => void
}

const EditDataForm: FC<IProps> = ({ methods, onSubmit }) => {
	const { userData } = useProfileData()
	const optionsCodeCountries = countries.map(item => ({
		label: item.dial_code,
		value: item.dial_code,
		prev: item.flag
	}))

	const {
		formState: { errors }
	} = methods

	useEffect(() => {
		if (userData) {
			const invalidDate = userData.birthday
				? parse(userData.birthday, 'yyyy-MM-dd', new Date())
				: undefined

			const code = optionsCodeCountries.find(item => {
				const countNum = item.value.length

				const codeValue = `+${userData.phone}`?.slice(0, countNum)
				return item.value === `${codeValue}`
			})

			const codeLength = code ? code.value.length - 1 : undefined
			const phone = userData.phone?.slice(codeLength)

			methods.reset({
				name: userData.name || '',
				code: code?.value,
				email: userData.email,
				phone: phone || '',
				birthday: invalidDate
			})
		}
	}, [userData])

	return (
		<>
			<FormWrapper
				methods={methods}
				onSubmit={onSubmit}
				onError={e => {}}
				className={styles.wrapper}
			>
				<div className={styles.title}>
					{getMessageLocality('edit-info_route')}
				</div>

				<Input
					name='name'
					connect
					errorText={errors.name?.message?.toString()}
					placeholder={getMessageLocality('username')}
					isClear
					methods={methods}
					registerConfig={{
						maxLength: {
							value: 150,
							message: getMessageLocality('maximum_characters') + ' - 150'
						}
					}}
				/>

				<CalendarInput
					name='birthday'
					placeholder='dd/MM/yyyy'
					connect
					isMini
					isHigh
					errorText={errors.birthday?.message?.toString()}
					registerConfig={{
						validate: value => {
							if (!isValid(value) && value) {
								return getMessageLocality('corrected_date')
							}

							if (isBefore(value, new Date(1900, 0, 1))) {
								return getMessageLocality('corrected_date')
							}

							return true
						}
					}}
				/>

				<Input
					name='email'
					errorText={errors.email?.message?.toString()}
					connect
					placeholder={getMessageLocality('login_email')}
					disabled={true}
					isClear
					methods={methods}
					registerConfig={{
						pattern: {
							value: validEmail,
							message: getMessageLocality('correct_email')
						}
					}}
				/>
				<div className={styles.phoneWrapper}>
					<ReactSelect
						name='code'
						connect
						modifier={['simple', 'isHigh']}
						placeholder={'+7'}
						containerClassName={styles.phoneCode}
						className={styles.phone}
						options={optionsCodeCountries}
						isCode
					/>

					<Input
						name='phone'
						errorText={errors.phone?.message?.toString()}
						connect
						type='tel'
						containerClassName={styles.phoneDop}
						placeholder={getMessageLocality('phone')}
						isClear
						disabled={!methods.watch('code')}
						methods={methods}
						// registerConfig={{
						// 	pattern: {
						// 		value: PHONE_REGEX,
						// 		message: getMessageLocality('correct_phone')
						// 	}
						// }}
					/>
				</div>
				<ReactSelect
					name='country'
					connect
					modifier={['simple', 'isHigh']}
					placeholder={getMessageLocality('country')}
					isSearchable={false}
					isDisabled
					options={[]}
				/>

				<Button
					classNameContainer={styles.button}
					type='submit'
					title={getMessageLocality('save_editor')}
					modifiers={['gold', 'small']}
				/>
			</FormWrapper>
		</>
	)
}

export default EditDataForm
