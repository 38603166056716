import clsx from 'clsx'
import { CSSProperties, FC, HTMLAttributes } from 'react'
import { FormattedMessage } from 'react-intl'

import styles from './Loader.module.scss'

export interface IProps {
	text?: boolean
	absolute?: boolean
	isMini?: boolean
	customStyles?: CSSProperties | undefined
}

export const Loader: FC<IProps> = ({ text, absolute, isMini, customStyles }) => {
	return (
		<div
			style={customStyles}
			className={clsx(
				styles.loader,
				isMini && styles.miniLoader,
				absolute && styles.absolute
			)}
		>
			<svg viewBox='25 25 50 50'>
				<circle cx='50' cy='50' r='20' />
			</svg>
			{text ? null : (
				<div className={styles.text}>
					<FormattedMessage id='loader' />
				</div>
			)}
		</div>
	)
}
