import clsx from 'clsx'
import { FC } from 'react'

import { IPaymentHistoryTableItem } from '../payment-history-table.interface'

import styles from './Item-mobile.module.scss'
import PaymentStatus from '@/entities/profile/payment-status'
import { dateFormatter } from '@/shared/utils/dateFormatter'
import { useLocaleTranslation } from '@/shared/hooks/useLocaleTranslation'
import { useAppSelector } from '@/shared/model'
import { getMessageLocality } from '@/shared/utils'
import { TValuesLocality } from '@/shared/utils/getMessageLocality'

const TableItemMobile: FC<IPaymentHistoryTableItem> = ({
	tableItem,
	setPaymentDetailModal
}) => {
	const { locale } = useAppSelector(state => state.defaultReducer)
	const name = useLocaleTranslation(tableItem.item, 'name')

	const item = {
		id: `#${tableItem.id}`,
		section: tableItem.type,
		date: dateFormatter(tableItem.date, locale),
		product: name,
		price: `${tableItem.price} ₿` || '-'
	}

	const onClickButton = () =>
		setPaymentDetailModal({ isOpen: true, itemId: tableItem.id })

	return (
		<div className={styles.wrapper}>
			<div className={styles.dataWrapper}>
				{Object.entries(item).map(([key, value], index) => {
					const label = key as TValuesLocality

					if (label === 'section') {
						return (
							<div className={styles.item} key={index}>
								<div className={styles.label}>{getMessageLocality(label)}</div>
								{/* @ts-ignore */}
								<div className={styles[key]}>{getMessageLocality(value)}</div>
							</div>
						)
					}
					return (
						<div className={styles.item} key={index}>
							<div className={styles.label}>{getMessageLocality(label)}</div>
							<div className={styles[key]}>{value}</div>
						</div>
					)
				})}
			</div>

			<button
				type='button'
				onClick={onClickButton}
				className={clsx(styles.details, styles.buttonWrapper)}
			>
				{getMessageLocality('view_details')}
			</button>
		</div>
	)
}

export default TableItemMobile
