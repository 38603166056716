import { FC, useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import { FormattedMessage } from 'react-intl'

import styles from './GameList.module.scss'
import { CardSlot } from './ui'
import { useGameList } from '@/features/games/games-list/api/useGameList'
import { Loader } from '@/shared/ui/loader/Loader'

export const GameList: FC = () => {
	const { ref, inView } = useInView({
		threshold: 0
	})
	const { games, isLoading, hasNextPage, fetchNextPage, isFetchingNextPage } =
		useGameList(12)

	useEffect(() => {
		if (inView && hasNextPage && !isFetchingNextPage) {
			fetchNextPage()
		}
	})

	return (
		<div className='GameBlock'>
			<div className={styles.slotsBlock}>
				{games?.length ? (
					<>
						{games?.map(item => (
							<CardSlot key={item.id} {...item} />
						))}
					</>
				) : !isLoading && !games?.length ? (
					<>
						<FormattedMessage id='not_found' />
					</>
				) : null}
			</div>
			{isLoading && <Loader />}
			{!isLoading && isFetchingNextPage && <Loader isMini />}
			<div ref={ref} />
		</div>
	)
}
