import {
	Children,
	FC,
	PropsWithChildren,
	useEffect,
	useMemo,
	useState
} from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import styles from './LayoutTabs.module.scss'
import TabButton from '@/entities/profile/tab-button'
import { TypeTabNames } from '@/widgets/profile/tabs/tabs.interface'

interface IProps extends PropsWithChildren {
	tabsData: { label: string; name: string }[]
	isSearchParams?: boolean
	isMobile: boolean
}
export const LayoutTabs: FC<IProps> = ({ tabsData, children, isMobile }) => {
	const [searchParams, setSearchParams] = useSearchParams()

	const [currentTab, setCurrentTab] = useState<string | null>(
		(!isMobile && tabsData[0].name) || null
	)

	const isTab = searchParams.get('tab')

	const onClickTabButton = (tabName: string) => {
		setCurrentTab(tabName)
		setSearchParams({ tab: tabName })
	}

	useEffect(() => {
		if (isTab) {
			setCurrentTab(isTab as TypeTabNames)
		}
	}, [isTab])

	const currentTabBodyIndex = useMemo(
		() => tabsData.findIndex(item => item.name === currentTab),
		[currentTab]
	)

	return (
		<div className={styles.container}>
			{!isMobile && (
				<div className={styles.tabs}>
					{tabsData.map(tab => (
						<TabButton
							key={tab.name}
							label={tab.label}
							isActive={tab.name === currentTab}
							onClick={() => onClickTabButton(tab.name)}
						/>
					))}
				</div>
			)}
			{isMobile && !isTab && (
				<div className={styles.tabsList}>
					{tabsData.map(tab => (
						<div
							key={tab.name}
							className={styles.tabMobile}
							onClick={() => onClickTabButton(tab.name)}
						>
							{tab.label}
						</div>
					))}
				</div>
			)}
			{((isMobile && isTab) || !isMobile) && (
				<div className={styles.content}>
					{Children.map(children, (child, index) => {
						if (currentTabBodyIndex === index) return <>{child}</>
						return null
					})}
				</div>
			)}
		</div>
	)
}
