// Библиотечные компоненты
import { FC } from 'react'
import { FormattedDate } from 'react-intl'

// Типизация
// Стили
import '@/Assets/sass/components/CategoryItemBlock.sass'

interface IProps {
	img?: string
	title: string
	description: string
	description_formated?: boolean
	date?: string
}

export const CategoryItemBlock: FC<IProps> = props => {
	const { img, title, description, date } = props
	return (
		<div className='CategoryItemList'>
			{!!img && <img src={img} alt='go' />}
			<div className={`CategoryItemList__Info${img ? ' noneFull' : ' Full'}`}>
				<div className='row'>
					<div className='h3'>{title}</div>
					{!!date && (
						<div className='date'>
							<FormattedDate value={new Date(date)} />
						</div>
					)}
				</div>
				<p>{description}</p>
			</div>
		</div>
	)
}
export default CategoryItemBlock
