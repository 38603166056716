import { FC, useEffect } from 'react'
import { Link } from 'react-router-dom'

import ExperienceProgress from '../user-data/ui/experience-progress'

import styles from './CardLevelInfo.module.scss'
import { ICardLevelInfo } from './card-level-info.interface'
import card_level_info_bronze_cn from '@/Assets/img/User/cn/card_level_info_bronze.webp'
import card_level_info_gold_cn from '@/Assets/img/User/cn/card_level_info_gold.webp'
import card_level_info_platinum_cn from '@/Assets/img/User/cn/card_level_info_platinum.webp'
import card_level_info_silver_cn from '@/Assets/img/User/cn/card_level_info_silver.webp'
import card_level_info_bronze_en from '@/Assets/img/User/en/card_level_info_bronze.webp'
import card_level_info_gold_en from '@/Assets/img/User/en/card_level_info_gold.webp'
import card_level_info_platinum_en from '@/Assets/img/User/en/card_level_info_platinum.webp'
import card_level_info_silver_en from '@/Assets/img/User/en/card_level_info_silver.webp'
import { CollectButton } from '@/entities/profile/collect-button'
import {
	bronzeIcon,
	goldIcon,
	platinumIcon,
	silverIcon
} from '@/shared/assets/profile/img'
import { FINAL_QUIZ } from '@/shared/constants'
import { useIsMobile } from '@/shared/hooks/useIsMobile'
import { useLocalStorage } from '@/shared/hooks/useLocalStorage'
import { useAppSelector } from '@/shared/model'

const CardLevelInfo: FC<ICardLevelInfo> = ({
	levelName = 'Platinum',
	userData,
	setIsModalOpened
}) => {
	const { localValue, deleteLocalStorageValue } = useLocalStorage(FINAL_QUIZ)
	const nameLevel = levelName || 'Platinum'
	const { isMobile } = useIsMobile(768)
	const { locale } = useAppSelector(state => state.defaultReducer)

	const onClickCollectButton = () => {
		setIsModalOpened(true)
	}

	useEffect(() => {
		if (localValue && userData?.bonus_is_ready) {
			onClickCollectButton()
			deleteLocalStorageValue()
		}
	}, [localValue, userData])

	const dataBonuses = {
		Bronze: {
			link: '/profile/bonuses',
			frame: bronzeIcon,
			image:
				locale === 'en' ? card_level_info_bronze_en : card_level_info_bronze_cn,
			alt: `bronze card`
		},
		Silver: {
			link: '/profile/bonuses',
			frame: silverIcon,

			image:
				locale === 'en' ? card_level_info_silver_en : card_level_info_silver_cn,
			alt: `silver card`
		},
		Gold: {
			link: '/profile/bonuses',
			frame: goldIcon,

			image:
				locale === 'en' ? card_level_info_gold_en : card_level_info_gold_cn,
			alt: `gold card`
		},
		Platinum: {
			link: '/profile/bonuses',
			frame: platinumIcon,
			image:
				locale === 'en'
					? card_level_info_platinum_en
					: card_level_info_platinum_cn,
			alt: `platinum card`
		},
		Unlimited: {
			link: '/profile/bonuses',
			frame: platinumIcon,
			image:
				locale === 'en'
					? card_level_info_platinum_en
					: card_level_info_platinum_cn,
			alt: `unlimited card`
		}
	}
	const ratingData = dataBonuses[nameLevel] || dataBonuses.Platinum

	return (
		<div className={styles.wrapper}>
			{userData && !!isMobile && (
				<div>
					<ExperienceProgress
						rating={userData.rating}
						starData={userData.star}
						infoIcon={true}
						isMobile={true}
						isRow={true}
						blockExperience={
							<div className={styles.image}>
								<img src={ratingData.frame} alt='frame' />
							</div>
						}
					/>
					<CollectButton
						classContainer={styles.collect}
						onClick={onClickCollectButton}
						disabled={!userData.bonus_is_ready}
					/>
				</div>
			)}
			{!isMobile && (
				<Link to={ratingData.link} className={styles.card}>
					<img src={ratingData.image} alt={ratingData.alt} />
				</Link>
			)}
		</div>
	)
}

export default CardLevelInfo
