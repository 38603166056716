import { FC, useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'

import styles from './BettingHistory.module.sass'
import { useBettingHistory } from './useBettingHistory'
import BettingHistoryTable from '@/features/profile/betting-history-table'
import HistoryFilters from '@/features/profile/history-filters'
import { IHistoryFiltersForm } from '@/shared/types/global.types'
import { Loader } from '@/shared/ui/loader/Loader'

const BettingHistory: FC = () => {
	const [historyFilters, setHistoryFilters] =
		useState<IHistoryFiltersForm | null>({
			show: 'show_all',
			fromDate: undefined,
			toDate: undefined
		})
	console.log({ historyFilters })

	const {
		isLoading,
		isFetching,
		isFetchingNextPage,
		bettingHistory,
		hasNextPage,
		isEmpty,
		fetchNextPage
	} = useBettingHistory(historyFilters)

	const { ref, inView } = useInView({
		threshold: 0
	})

	useEffect(() => {
		if (inView && hasNextPage && !isFetchingNextPage && !isEmpty) {
			fetchNextPage()
		}
	})

	return (
		<div className={styles.container}>
			<HistoryFilters setHistoryFilters={setHistoryFilters} />
			{isLoading ? (
				<Loader />
			) : (
				<BettingHistoryTable bettingHistory={bettingHistory} />
			)}
			<div ref={ref} />
		</div>
	)
}

export default BettingHistory
