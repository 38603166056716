import { useQuery, useQueryClient } from '@tanstack/react-query'

import { useAlertMessage } from '@/shared/hooks/useAlertMessage'
import UserService from '@/shared/services/user.service'

export const useReferralLink = () => {
	const queryClient = useQueryClient()
	const { addAlertMessage } = useAlertMessage()

	useQuery(['get referral users'], () => UserService.getReferralUsers(), {
		select: ({ data }) => data,
		onSuccess: () => {
			queryClient.invalidateQueries(['get user data'])
		},
		refetchInterval: 36000000
	})

	return useQuery(['get referral link'], () => UserService.getReferralLink(), {
		select: ({ data }) => {
			return data
		},
		onError: (error: any) => {
			addAlertMessage(error, 'error')
		}
	})
}
