import styles from './Deposit.module.scss'
import { DepositList } from '@/features/profile/deposit-list/ui'
import { IDepositPayment } from '@/features/profile/deposit-list/ui/deposit-list.interface'
import { messages } from '@/i18n/messages'
import {
	DepositAlipayComponent,
	UnionPayComponent,
	WeChatComponent
} from '@/shared/assets/profile/svg'
import { useIsMobile } from '@/shared/hooks/useIsMobile'
import { useAppSelector } from '@/shared/model'

export const Deposit = () => {
	const { locale } = useAppSelector(state => state.defaultReducer)
	const { isMobile } = useIsMobile()
	const postfixMethod = isMobile ? '_H5' : ''
	const dataDeposit: IDepositPayment[] = [
		{
			title: messages[locale].alipay,
			id: 1,
			img: <DepositAlipayComponent />,
			method: ('ALIPAY' + postfixMethod) as 'ALIPAY' | 'ALIPAY_H5'
		},
		{
			title: messages[locale].weChatPlay,

			id: 3,
			img: <WeChatComponent />,
			method: ('WEIXIN' + postfixMethod) as 'WEIXIN' | 'WEIXIN_H5'
		},
		{
			title: messages[locale].unionPay,
			id: 2,
			img: <UnionPayComponent />,
			method: 'TAOBAO'
		}
	]

	return (
		<div className={styles.wrapper}>
			<h3 className={styles.title}>{messages[locale].depositDescription}</h3>

			<DepositList data={dataDeposit} />
		</div>
	)
}
