import React, { FC } from 'react'

import { backgroundQuiz_1, image_1, image_1_mobi } from '@/Assets/img/quiz'
import { QuizContent } from '@/entities/quiz-content'
import { QuizLayout } from '@/features/quiz'
import { useIsMobile } from '@/shared/hooks/useIsMobile'
import { QuizLayoutOne } from '@/shared/ui/quiz-layout/quiz-layout-1'
import { getMessageLocality } from '@/shared/utils'

interface IProps {
	onNextStep: () => void
}

export const QuizOne: FC<IProps> = ({ onNextStep }) => {
	const { isMobile } = useIsMobile(768)
	return (
		<QuizLayout background={backgroundQuiz_1}>
			<QuizLayoutOne
				image={isMobile ? image_1_mobi : image_1}
				onNextStep={onNextStep}
				title={getMessageLocality('questionOneVarian1')}
			/>
		</QuizLayout>
	)
}
