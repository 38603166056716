import cn from 'clsx'
import { FC, ReactElement } from 'react'

import styles from './GradientBorderText.module.sass'

interface IProps {
	text: string
	icon?: ReactElement<any, any>
	className?: string
}

const GradientBorderText: FC<IProps> = ({ text, icon, className }) => {
	return (
		<div className={cn(styles.gradientBorderText, className)}>
			{icon}
			<span>{text} XP</span>
		</div>
	)
}

export default GradientBorderText
