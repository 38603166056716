// Библиотечные компоненты
import { Dispatch, FC, useEffect, useState } from 'react'
import { FieldValues, useForm } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'
import { useNavigate } from 'react-router-dom'

import styles from './Register.module.scss'
// Изображения
import cn_background from '@/Assets/img/Auth/cn_baground.webp'
import en_background from '@/Assets/img/Auth/en_baground.webp'
// Стили
import '@/Assets/sass/components/Auth.sass'
import { TStepAuth } from '@/app/providers/protected-router'
import { messages } from '@/i18n/messages'
import { CloseIconSimpleJSX } from '@/shared/assets/common/svg'
import { useErrorAlert } from '@/shared/hooks/useErrorAlert'
import { useTimerBack } from '@/shared/hooks/useTimerBack'
import { useAppSelector } from '@/shared/model'
import AuthService from '@/shared/services/auth/auth.service'
// Компоненеты
// Экшны
import { Checkbox, FormWrapper, Input } from '@/shared/ui/form'
import { Loader } from '@/shared/ui/loader/Loader'

interface IProps {
	page: (page: TStepAuth) => void
	setDataAuthForm: Dispatch<React.SetStateAction<any>>
	widget?: boolean
	refCode?: string
}

export const Registration: FC<IProps> = ({
	page,
	refCode,
	widget = false,
	setDataAuthForm
}) => {
	const { setTimerStart, timer, timerStart } = useTimerBack(60)
	const [timerVisible, setTimerVisible] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const form = useForm({ mode: 'onBlur' })
	const {
		formState: { errors }
	} = form
	const [checked, setChecked] = useState(true)
	const { addErrorAlert } = useErrorAlert()

	const { loader, locale } = useAppSelector(state => state.defaultReducer)

	const navigate = useNavigate()
	const onSendCode = async (data?: FieldValues) => {
		const dataForm = data || form.getValues()

		const formData = new FormData()
		setDataAuthForm(dataForm)
		for (const key in dataForm) {
			formData.append(key, dataForm[key])
		}
		setIsLoading(true)
		AuthService.register(formData, refCode)
			.then(resp => {
				if (resp.status === 201) {
					page('four-digit-code')
				}
			})
			.catch((e: any) => {
				const message = e?.response?.data?.detail
				if (message?.includes('Too many requests')) {
					setTimerStart(true)
					setTimerVisible(true)
				}
				addErrorAlert(e)
			})
			.finally(() => {
				setIsLoading(false)
			})
	}
	const onRegistration = (data: FieldValues) => {
		onSendCode(data)
	}

	const onCloseBlock = () => {
		if (widget) return page('')
		if (refCode) return navigate('/')
		navigate(-1)
	}

	const backgroundLocale = locale === 'en' ? en_background : cn_background
	const disabledButton = !checked || isLoading || timerStart

	return !loader ? (
		<div className='FormBlock'>
			<FormWrapper
				onSubmit={onRegistration}
				methods={form}
				className='RegisterForm'
				style={{ background: `url('${backgroundLocale}'), #231F20` }}
			>
				<div className='leftBlock'>
					<div className='formGroup'>
						<div className='close' onClick={onCloseBlock}>
							<CloseIconSimpleJSX />
						</div>
						<div className='title'>
							<FormattedMessage id='title_register' />
						</div>
						<div>
							<FormattedMessage id='login_email'>
								{message => (
									<Input
										mask=''
										name='email'
										type='email'
										connect
										placeholder={message.toString()}
										style={{ marginTop: '-5px' }}
										errorText={errors?.email?.message}
										registerConfig={{
											required: messages[locale].required_field,
											pattern: {
												value: /\S+@\S+\.\S+/,
												message: messages[locale].correct_email
											}
										}}
									/>
								)}
							</FormattedMessage>
						</div>
						<div>
							<FormattedMessage id='username'>
								{message => (
									<Input
										mask=''
										name='name'
										type='name'
										connect
										placeholder={message.toString()}
										style={{ marginTop: '-5px' }}
										errorText={errors?.name?.message}
										registerConfig={{
											required: messages[locale].required_field
										}}
									/>
								)}
							</FormattedMessage>
						</div>
						<div className='inputPass'>
							<FormattedMessage id='login_pass'>
								{message => (
									<Input
										mask=''
										name='password'
										type='password'
										connect
										isPassword
										placeholder={message.toString()}
										errorText={errors?.password?.message}
										registerConfig={{
											required: messages[locale].required_field,
											minLength: {
												value: 8,
												message: messages[locale].minimum_characters + ' - 8'
											}
										}}
									/>
								)}
							</FormattedMessage>
						</div>
						<button
							className='buttonRedHoverBlackBorderGold'
							type='submit'
							data-content={messages[locale].register}
							disabled={disabledButton}
						/>

						{timerVisible && (
							<div className={styles.actionStep}>
								<FormattedMessage id='repeatCode' />
								{timerStart ? (
									<div>
										{timerStart ? `${timer} ` : ''}
										{timerStart && <FormattedMessage id='second' />}
									</div>
								) : (
									<span onClick={() => !disabledButton && onSendCode()}>
										<FormattedMessage id='send' />
									</span>
								)}
							</div>
						)}

						<div className={styles.actionStep}>
							<FormattedMessage id='have_account' />
							<span onClick={() => page('login')}>
								<FormattedMessage id='login' />
							</span>
						</div>
						<div className={styles.actionStep}>
							<FormattedMessage id='have_code' />
							<span onClick={() => page('have-code')}>
								<FormattedMessage id='verify' />
							</span>
						</div>
						<FormattedMessage id='accept_terms_conditions'>
							{message => (
								<Checkbox
									className={styles.checkbox}
									title={message.toString()}
									checked={checked}
									modifier={['center']}
									onChange={() => {
										setChecked(!checked)
									}}
								/>
							)}
						</FormattedMessage>
					</div>
				</div>
				<div className='rightBlock' />
			</FormWrapper>
		</div>
	) : (
		<Loader />
	)
}

export default Registration
