import cn from 'clsx'
import { FC } from 'react'

import styles from './LevelAvatar.module.scss'
import bronzeFrame from '@/Assets/img/User/bronze.webp'
import goldFrame from '@/Assets/img/User/gold.webp'
import platinumFrame from '@/Assets/img/User/platinum.webp'
import silverFrame from '@/Assets/img/User/silver.webp'
import { avatarIcon } from '@/shared/assets/profile/img'
import { TypeRatingName } from '@/shared/types/user.types'

interface IProps {
	levelType?: TypeRatingName
	avatarLink?: string
	name?: string | null
	className?: string
}

const LevelAvatar: FC<IProps> = ({
	levelType,
	avatarLink = avatarIcon,
	name,
	className
}) => {
	const getFrame = () => {
		if (levelType === 'Bronze') return bronzeFrame
		if (levelType === 'Silver') return silverFrame
		if (levelType === 'Gold') return goldFrame
		if (levelType === 'Platinum') return platinumFrame
		if (levelType === 'Unlimited') return platinumFrame
	}

	return (
		<div className={cn(styles.levelAvatar, className)}>
			<img className={styles.frame} src={getFrame()} alt='levelType' />
			<img className={styles.editAvatar} src={avatarLink} alt='face' />
			<div className={styles.name}>{name}</div>
		</div>
	)
}

export default LevelAvatar
