import { useQuery } from '@tanstack/react-query'

import AnalyticsService from '@/shared/services/analytics.service'

export const useAnalyticDetail = (id?: number) => {
	return useQuery(
		['get analytic detail' + id],
		() => AnalyticsService.getById(id!),
		{
			select: ({ data }) => data,
			enabled: !!id,
			retry: 0
		}
	)
}
