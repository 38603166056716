import clsx from 'clsx'
import { QuizContent } from '@/entities/quiz-content'
import { QuizLayout } from '@/features/quiz'
import React, { FC } from 'react'

import { TQuiz } from '../..'

import styles from './lang.module.scss'
import { backgroundQuiz_1, image_2, lang_bg } from '@/Assets/img/quiz'
import { useLocalStorage } from '@/shared/hooks/useLocalStorage'
import { useAppDispatch } from '@/shared/model'
import { setLang } from '@/shared/store/actions/defaultActions'
import { Button } from '@/shared/ui/form'

export const QuizLang: FC<TQuiz> = ({ onNextStep }) => {
	const dispatch = useAppDispatch()

	const { setLocalStorageValue } = useLocalStorage('locale')

	const onChangeLang = (lang: string) => {
		setLocalStorageValue(lang)
		dispatch(setLang(lang, true))

		document.documentElement.lang = lang
		onNextStep?.()
	}
	return (
		<QuizLayout background={backgroundQuiz_1}>
			<div
				style={{
					margin: '0 auto',
					height: '100%',
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'space-between',
					alignItems: 'center',
					overflow: 'hidden'
				}}
			>
				<QuizContent
					title={''}
					maxWidth='717px'
					onNextStep={onNextStep}
					classNameWrapper={styles.wrapper}
					buttonsJSX={
						<>
							<Button
								title={'english'}
								modifiers={['gold']}
								onClick={() => onChangeLang('en')}
							/>
							<Button
								title={'中文'}
								modifiers={['gold']}
								onClick={() => onChangeLang('cn')}
							/>
						</>
					}
				/>

				<div className={styles.images} />
				{/* <div className='animate__animated animate__backInUp'>
					<img src={lang_bg} alt='img' />
				</div> */}
			</div>
		</QuizLayout>
	)
}
