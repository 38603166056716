import { FC } from 'react'

import styles from './ExpertDetail.module.scss'
import { ExpertsBgJsx } from '@/shared/assets/experts/svg'
import CollapsibleText from '@/shared/ui/collapsible'

interface IProps {
	title: string
	category: string
	description: string
	imgLink: string
}
export const ExpertDetailInfo: FC<IProps> = ({
	category,
	description,
	title,
	imgLink
}) => {
	const updateDescription = description.replaceAll('\n', '<br/>')

	return (
		<div className={styles.wrapper}>
			<div className={styles.images}>
				<div className={styles.bgImages}>
					<ExpertsBgJsx />
				</div>
				<img src={imgLink} alt={imgLink} />
			</div>
			<div className={styles.info}>
				<div className={styles.title}>{title}</div>
				<div className={styles.category}>{category}</div>
				<div className={styles.description}>
					<CollapsibleText countString={4} text={updateDescription} />
				</div>
			</div>
		</div>
	)
}
