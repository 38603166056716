import {
	type TypedUseSelectorHook,
	useDispatch,
	useSelector
} from 'react-redux'

import { AppDispatch, RootState } from '@/shared/store/store'

/**
 * Типизированный useDispatch
 * */
export const useAppDispatch = useDispatch<AppDispatch>

/**
 * Типизированный useSelector с полной типизацией store
 * */
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

