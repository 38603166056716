import React, { FC, useState } from 'react'

import styles from './ExpertForcast.module.scss'
import { BlogCard } from '@/entities/blog/ui'
import { useAnalyticDetail, useAnalyticsList } from '@/features/sports/api'
import { useIsMobile } from '@/shared/hooks'
import { useTranslationField } from '@/shared/hooks/useTranslationField'
import { IAnalytic } from '@/shared/types/analytics.types'
import { Loader } from '@/shared/ui/loader/Loader'
import { getMessageLocality } from '@/shared/utils'

interface IProps {
	authorName: string
	idExpert: number
}

export const ExpertForCast: FC<IProps> = ({ authorName, idExpert }) => {
	const [analyticId, setAnalyticId] = useState<number>()
	const { analyticsData, isFetching, refAnalytic } = useAnalyticsList(
		12,
		authorName
	)
	const { data, isLoading } = useAnalyticDetail(analyticId)
	const { getField } = useTranslationField()
	const { isMobile: isTablet } = useIsMobile()

	const getOpponentName = (data: IAnalytic.DataAnalytic, two?: boolean) => {
		const text = two
			? getField(data.game, 'opponent_two_name')
			: getField(data.game, 'opponent_one_name')
		if (!text) return ''
		return text.slice(0, 180)
	}

	const onSliceText = (data: IAnalytic.DataAnalytic) => {
		const text = getField(data, 'text')
		if (!text) return ''
		return text.slice(0, 180)
	}

	return (
		<div
			className={styles.wrapper}
			style={{ minHeight: isFetching ? '270px' : 'auto' }}
		>
			{isFetching && <Loader absolute />}
			{!isFetching && !analyticsData?.length && (
				<p className={styles.notFound}>{getMessageLocality('not_found')}</p>
			)}
			{analyticsData?.map(item => {
				return (
					<BlogCard
						key={item.id}
						opponent_one_image={item.game.opponent_one_image}
						opponent_one_name={getOpponentName(item)}
						opponent_two_image={item.game.opponent_two_image}
						opponent_two_name={getOpponentName(item, true)}
						data={item}
						start_date={item.game.start_date}
						title={getField(item.tournament, 'name')}
						description={getField(item, 'text')}
						visible={true}
						isForcast={isTablet}
						dataMore={data}
						isLoading={isLoading}
						onOpenMore={() => setAnalyticId(item.id)}
						to={`/experts/item/${idExpert}/forcast/${item.id}`}
					/>
				)
			})}
			<div ref={refAnalytic} />
		</div>
	)
}
