export const EXPERT_SPORTS_NAME = [
	{
		name: 'Football',
		sportName: 'Football'
	},
	{
		name: 'Basketball',
		sportName: 'Basketball'
	},
	{
		name: 'Single Betting',
		sportName: null
	},
	{
		name: 'Express Bets',
		sportName: null
	}
]
