import clsx from 'clsx'
import { FC } from 'react'

import { Calendar } from '../calendar'
import { ConnectCalendar } from '../form-connect'
import { IPropCalendar } from '../form.interface'

import styles from './CalendarInput.module.scss'

export const CalendarInput: FC<IPropCalendar> = ({
	connect,
	registerConfig,
	containerClassName,
	name,
	onChange,
	defaultValue,
	value,
	calendarRef,
	isMini,
	isHigh,
	errorText,
	...restProps
}) => {
	const CalendarComponent = connect ? ConnectCalendar : Calendar

	return (
		<div
			className={clsx(
				styles.wrapper,
				errorText && styles.inputError,
				isMini && styles.mini,
				isHigh && styles.isHigh,
				containerClassName
			)}
		>
			<CalendarComponent
				name={name}
				value={value}
				defaultValue={defaultValue}
				calendarRef={calendarRef}
				onChange={onChange}
				{...(connect
					? {
							registerConfig
					  }
					: {})}
				{...restProps}
			/>
			{errorText && typeof errorText === 'string' && (
				<div className={styles.error}>
					<span>{errorText}</span>
				</div>
			)}
		</div>
	)
}
