import clsx from 'clsx'
import { FC } from 'react'
import { Link } from 'react-router-dom'

import styles from './Breadcrumbs.module.scss'
import { ArrowBack } from '@/shared/assets/common/svg'

interface IProps {
	setIsOpenMenu: (val: boolean) => void
	isOpenMenu: boolean
	navBreadCrumbs: ({ name: string; link?: string } | null)[]
	isTab?: boolean
}

export const BreadcrumbsExtra: FC<IProps> = ({
	isOpenMenu,
	setIsOpenMenu,
	isTab,
	navBreadCrumbs = []
}) => {
	const linkBack = isTab
		? ''
		: navBreadCrumbs?.findLast(item => item?.link)?.link

	if (navBreadCrumbs === null) return null
	return (
		<>
			{navBreadCrumbs.length > 1 && (
				<>
					{isOpenMenu ? (
						<div
							className={clsx(styles.backSpace)}
							onClick={() => setIsOpenMenu(false)}
						>
							<ArrowBack />
						</div>
					) : (
						<Link
							to={linkBack || ''}
							className={clsx(styles.backSpace, styles.arrow)}
						>
							<ArrowBack />
						</Link>
					)}
				</>
			)}
		</>
	)
}
