import { FC, useState } from 'react'

import { ModalDeposit } from '../../modal'

import { DepositCard } from './DepositCard'
import styles from './DepositList.module.scss'
import { IDepositPayment } from './deposit-list.interface'

interface IProps {
	data: IDepositPayment[]
}

export const DepositList: FC<IProps> = ({ data }) => {
	const [isOpen, setIsOpen] = useState(false)
	const [selectDeposit, setSelectDeposit] = useState<IDepositPayment>()

	const onSelectedDeposit = (data: IDepositPayment) => {
		setSelectDeposit(data)
		setIsOpen(true)
	}

	return (
		<>
			<div className={styles.paymentsContainer}>
				{data.map(item => (
					<DepositCard key={item.id} data={item} onSelect={onSelectedDeposit} />
				))}
			</div>
			<ModalDeposit
				isOpen={isOpen}
				onCLose={() => setIsOpen(false)}
				data={selectDeposit}
			/>
		</>
	)
}
