import Layout from '@/shared/ui/layout'
import ExpertsWidget from '@/widgets/experts'

const Experts = () => {
	return (
		<Layout
			fullWidth={false}
			position='center'
			title='experts_route'
			description='match_centre_description'
			titlePage='experts_route'
			descriptionMeta='page_description_sport_match_center'
			style={{ maxWidth: '1920px' }}
		>
			<ExpertsWidget />
		</Layout>
	)
}

export default Experts
