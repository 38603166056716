import Cookies from 'js-cookie'
import { ITokens } from '../../store/user/user.interface'

export const saveTokensStorage = (data: ITokens) => {
	Cookies.set('refreshToken', data.refresh)
	Cookies.set('accessToken', data.access)
	localStorage.setItem('access', data.access)
	localStorage.setItem('refresh', data.refresh)
}

export const removeTokensStorage = () => {
	Cookies.remove('refreshToken')
	Cookies.remove('accessToken')
	localStorage.removeItem('access')
	localStorage.removeItem('refresh')
}
