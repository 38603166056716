import cn from 'clsx'
import { FC } from 'react'

import { ITabButton } from './TabButton.interface'
import styles from './TabButton.module.scss'
import { getMessageLocality } from '@/shared/utils'
import { TValuesLocality } from '@/shared/utils/getMessageLocality'

const TabButton: FC<ITabButton> = ({ label, isActive, ...rest }) => {
	const labelMessage = (label + '_route') as TValuesLocality
	const labelButton = getMessageLocality(labelMessage) || label
	return (
		<button
			type='button'
			className={cn(styles.tabButton, { [styles.active]: isActive })}
			{...rest}
		>
			{labelButton}
		</button>
	)
}

export default TabButton
