import { FC } from 'react'
import { FormattedMessage } from 'react-intl'

import styles from '../PaymentHistoryTable.module.scss'
import { IPaymentHistoryTableItem } from '../payment-history-table.interface'

import PaymentStatus from '@/entities/profile/payment-status'
import { dateFormatter } from '@/shared/utils/dateFormatter'
import { useLocaleTranslation } from '@/shared/hooks/useLocaleTranslation'
import { useAppSelector } from '@/shared/model'
import { getMessageLocality } from '@/shared/utils'

const TableItem: FC<IPaymentHistoryTableItem> = ({
	tableItem,
	setPaymentDetailModal
}) => {
	const { locale } = useAppSelector(state => state.defaultReducer)
	const name = useLocaleTranslation(tableItem.item, 'name')
	const description = useLocaleTranslation(tableItem.item, 'description')

	
	const item = {
		id: `#${tableItem.id}`,
		status: tableItem.type,
		date: dateFormatter(tableItem.date, locale),
		product: name ,
		// product: tableItem.type === 'analytic' ? description : name,
		deposit: `${tableItem.price} ₿` || '-'
	}

	const onClickButton = () =>
		setPaymentDetailModal({ isOpen: true, itemId: tableItem.id })

	return (
		<div className={styles.tableItem}>
			{Object.entries(item).map(([key, value], index) => {
				if (key !== 'status')
					return (
						<p className={styles[key]} key={index}>
							{value}
						</p>
					)
				return (
					<p className={styles[key]} key={index} >
						{getMessageLocality(value)}
					</p>
				)
			})}
			<button type='button' onClick={onClickButton} className={styles.details}>
				<FormattedMessage id='view_details' />
			</button>
		</div>
	)
}

export default TableItem
