import clsx from 'clsx'
import { FC } from 'react'


import styles from './NewCardSide.module.scss'

interface IProps {
	img: string
	images_path?: string
	title: string
	visible?: boolean
	price?: number
	filter?: boolean
}

export const NewCardSide: FC<IProps> = ({
	title,
	visible = true,
	filter,
	img
}) => {
	const image =
		img ||
		`https://media-cldnry.s-nbcnews.com/image/upload/t_nbcnews-fp-1200-630,f_auto,q_auto:best/newscms/2019_01/2705191/nbc-social-default.png`

	return (
		<div className={styles.wrapper}>
			<div className={styles.title}>{title}</div>
			<img
				className={clsx(styles.background, filter && styles.filter)}
				alt={title}
				src={image}
			/>
		</div>
	)
}
