import clsx from 'clsx'
import { FC, MouseEvent, useEffect, useState } from 'react'
import ReactDOM from 'react-dom'

import styles from './Modal.module.sass'
import { TModal } from './modal.type'
import { CloseIconSimpleJSX, closeModalIcon } from '@/shared/assets/common/svg'
import useOutsideClick from '@/shared/hooks/useOutsideClick'

interface IPortal {
	children?: React.ReactNode
	disabledPortal?: boolean
}

const Portal: FC<IPortal> = ({ children, disabledPortal }) => {
	const [container] = useState(() => document?.createElement('div'))

	useEffect(() => {
		document.body.appendChild(container)
		return () => {
			document.body.removeChild(container)
		}
	}, [])

	if (disabledPortal) return children

	return ReactDOM.createPortal(children, container)
}

const Modal: FC<TModal> = ({
	className,
	children,
	isOpen,
	onClose,
	modifers,
	disabledPortal,
	title = ''
}) => {
	const { ref } = useOutsideClick(false)

	const mod = {
		simple: false,
		small: false,
		medium: false,
		zeroOffset: false,
		light: false,
		blur: false,
		absolute: false,
		deposit: false,
		inherit: false,
		tiny: false
	}

	modifers?.forEach(item => {
		mod[item] = true
	})

	const onKeyDown = (e: globalThis.KeyboardEvent) => {
		if (e.code === 'Escape') {
			onClose()
		}
	}
	useEffect(() => {
		if (isOpen) document.body.style.overflowY = 'hidden'
		if (isOpen) document.body.addEventListener('keydown', onKeyDown)

		return () => {
			document.body.style.overflowY = 'auto'
			document.body.removeEventListener('keydown', onKeyDown)
		}
	}, [isOpen])

	const closeModal = (e: MouseEvent<HTMLDivElement>) => {
		e.stopPropagation()
		onClose()
	}
	if (!isOpen) return null

	return (
		<Portal disabledPortal={disabledPortal}>
			<div
				className={clsx(
					styles.modalOverlay,
					mod.blur && styles.blur,
					mod.absolute && styles.absolute,
					mod.inherit && styles.inherit
				)}
				onClick={closeModal}
			>
				<div className={styles.modalContainer}>
					<div
						ref={ref}
						className={clsx(
							styles.modal,
							className,
							mod.simple && styles.simple,
							mod.zeroOffset && styles.zeroOffset,
							mod.tiny && styles.tiny,
							mod.small && styles.small,
							mod.medium && styles.modalMedium,
							mod.deposit && styles.deposit,
							mod.light && styles.light
						)}
						onClick={e => e.stopPropagation()}
					>
						<button type='button' className={styles.close} onClick={onClose}>
							{mod.simple && <CloseIconSimpleJSX />}
							{!mod.simple && <img src={closeModalIcon} alt='close' />}
						</button>
						{title && <div className={styles.title}>{title}</div>}
						<div className={clsx(styles.body)}>{children}</div>
					</div>
				</div>
			</div>
		</Portal>
	)
}

export default Modal
