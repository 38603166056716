import { Dispatch, FC, SetStateAction, useEffect, useRef } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import styles from './Tabs.module.scss'
import TabButton from '@/shared/sports/match-center/tab-button'

export interface ITabs {
	currentTab: number
	setCurrentTab: Dispatch<SetStateAction<number>>
	tabs?: any[]
}
export const Tabs: FC<ITabs> = ({ currentTab, setCurrentTab, tabs }) => {
	const navigate = useNavigate()
	const ref = useRef<HTMLDivElement>(null)
	const tabsRef = useRef<HTMLDivElement>(null)
	const location = useLocation()

	const onClickTabButton = (tabName: number) => {
		setCurrentTab(tabName)
		navigate(location, { state: tabName })
	}
	useEffect(() => {
		const offsetLeft = ref?.current?.offsetLeft
		if (offsetLeft) {
			tabsRef.current?.scrollTo({
				top: 0,
				left: offsetLeft - 70,
				behavior: 'smooth'
			})
		}
	}, [ref])

	return (
		<div className={styles.tabsContainer}>
			<div ref={tabsRef} className={styles.tabs}>
				{tabs?.map(tab => (
					<div ref={tab.id === location.state ? ref : null}>
						<TabButton
							name={tab.title}
							tabData={tab}
							key={tab.id}
							active={currentTab === tab.id}
							onClick={() => onClickTabButton(tab.id)}
						/>
					</div>
				))}
			</div>
		</div>
	)
}
