import clsx from 'clsx'
import { FC, PropsWithChildren } from 'react'

import styles from './Button.module.scss'

type TProps = PropsWithChildren & {
	onClick?: () => void
	modifiers?: ('gold' | 'success' | 'full' | 'small' | 'inverse' | 'large')[]
	title?: string
	disabled?: boolean
	className?: string
	classNameContainer?: string
	type?: 'submit' | 'reset' | 'button'
}

export const Button: FC<TProps> = ({
	type = 'submit',
	children,
	onClick,
	modifiers = [],
	title,
	disabled,
	className,
	classNameContainer
}) => {
	const mod = {
		gold: false,
		success: false,
		full: false,
		small: false,
		inverse: false,
		large: false
	}

	modifiers.forEach(item => {
		mod[item] = true
	})

	return (
		<div className={clsx(styles.wrapper, classNameContainer)}>
			<button
				className={clsx(
					styles.button,
					className,
					disabled && styles.disabled,
					mod.success && styles.success,
					mod.gold && styles.gold,
					mod.small && styles.small,
					mod.full && styles.fullWidth,
					mod.inverse && styles.inverse,
					mod.large && styles.large
				)}
				data-content={title}
				onClick={onClick}
				disabled={disabled}
				type={type}
			>
				{children}
			</button>
		</div>
	)
}
