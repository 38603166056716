import { FC } from 'react'
import { FormattedMessage } from 'react-intl'

import styles from './PaymentHistoryDetail.module.sass'
import { IPaymentHistoryDetail } from './payment-history-detail.interface'
import { ReactComponent as CopyIcon } from '@/Assets/svg/copy-icon.svg'
import { ReactComponent as XMarkIcon } from '@/Assets/svg/x-mark.svg'
import { dateFormatter } from '@/shared/utils/dateFormatter'
import { useLocaleTranslation } from '@/shared/hooks/useLocaleTranslation'
import useOutsideClick from '@/shared/hooks/useOutsideClick'
import { useAppSelector } from '@/shared/model'
import { Button } from '@/shared/ui/form'
import { getMessageLocality } from '@/shared/utils'

const PaymentHistoryDetail: FC<IPaymentHistoryDetail> = ({
	paymentItem,
	setPaymentDetailModal
}) => {
	const formatter = new Intl.NumberFormat('ru-RU')
	const description =
		useLocaleTranslation(paymentItem?.item || {}, 'description') ||
		//@ts-ignore
		paymentItem?.item.description

	const { locale } = useAppSelector(state => state.defaultReducer)

	const onClickCloseButton = () =>
		setPaymentDetailModal({ isOpen: false, itemId: 0 })

	const { ref } = useOutsideClick(false, onClickCloseButton, true)

	const onClickCopyButton = () => {
		navigator.clipboard.writeText(paymentItem?.item.promocode || '')
		onClickCloseButton()
	}
	return (
		<div className={styles.modal}>
			<div className={styles.cardWrapper} ref={ref}>
				<div className={styles.card}>
					<h3>
						<FormattedMessage id='view_details' />
					</h3>
					<div className={styles.row}>
						<p>ID:</p>
						<p>#{paymentItem?.id}</p>
					</div>
					<div className={styles.row}>
						<p>
							<FormattedMessage id='date' /> :
						</p>
						<p>{dateFormatter(paymentItem?.date || '', locale)}</p>
					</div>
					<div className={styles.row}>
						<p>
							<FormattedMessage id='price' />:
						</p>
						<p>{formatter.format(paymentItem?.price || 0)} ₿</p>
					</div>

					<div className={styles.row}>
						<p>
							<FormattedMessage id='description' />:
						</p>
						<p className={styles.description}>{description}</p>
					</div>
					{paymentItem?.item.promocode && (
						<div className={styles.row}>
							<p>
								<FormattedMessage id='promoCode' />:
							</p>
							<div>
								<p>{paymentItem?.item?.promocode}</p>
							</div>
							<button onClick={onClickCopyButton} className={styles.copy}>
								<CopyIcon />
							</button>
						</div>
					)}

					<button
						type='button'
						onClick={onClickCloseButton}
						className={styles.closeIcon}
					>
						<XMarkIcon />
					</button>
				</div>
				<div>
					<Button
						classNameContainer={styles.button}
						title={getMessageLocality('promoCodeCopy')}
						modifiers={['gold', 'small']}
						onClick={onClickCopyButton}
					/>
				</div>
			</div>
		</div>
	)
}

export default PaymentHistoryDetail
