import { FC } from 'react'

import styles from './EditProfile.module.scss'
import EditProfileContent from './edit-profile-content'
import { useProfileData } from '@/features/profile/api/useProfileData'
import { ReferralProgram } from '@/features/profile/referral-program'

const EditProfile: FC = () => {
	const { userData } = useProfileData()

	return (
		<div className={styles.wrapper}>
			<EditProfileContent userData={userData} />
			<ReferralProgram />
		</div>
	)
}

export default EditProfile
