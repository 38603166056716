import cn from 'clsx'
import { forwardRef } from 'react'
import 'react-datepicker/src/stylesheets/datepicker.scss'
import ReactInputMask from 'react-input-mask'

import styles from './CustomInput.module.scss'
import { ICustomInput } from './calendar.interface'
import { ReactComponent as CalendarIcon } from '@/Assets/svg/calendar-icon2.svg'

const CustomInput = forwardRef<HTMLInputElement, ICustomInput>(
	(
		{ onClick, onClickHandler, onChange, className, placeholderText, ...rest },
		ref
	) => {
		return (
			<label
				className={cn(styles.input, className)}
				onClick={() => {
					onClickHandler()
				}}
			>
				<ReactInputMask
					autoComplete='off'
					onChange={onChange}
					placeholder={'placeholderText'}
					mask={'99/99/9999'}
					{...rest}
				/>
				<CalendarIcon />
			</label>
		)
	}
)

export default CustomInput
