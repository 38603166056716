import { messages } from '@/i18n/messages'
import { store } from '@/shared/store/store'

export type TValuesLocality = keyof typeof messages.en

/**
 * Возвращает текст, который получаем из локали
 * @param message принимает текстовое поле сообщения
 * */

export const getMessageLocality = (message: TValuesLocality): string => {
	const locale = store.getState().defaultReducer.locale

	return messages[locale]?.[message] || ''
}
