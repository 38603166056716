import clsx from 'clsx'
import { FC } from 'react'
import Select from 'react-select'

import { ConnectSelect } from '../form-connect'
import {
	IPropsSelect,
	TModifiersSelect,
	TPropsComponent
} from '../form.interface'

import styles from './Select.module.scss'
import { customStyles } from './select-styles'
import { getMessageLocality } from '@/shared/utils'

interface IMyOptionsProps extends TPropsComponent {
	mod: TModifiersSelect
}
const MyOption = (props: IMyOptionsProps) => {
	const { innerProps, innerRef, isSelected, mod } = props
	return (
		<div
			ref={innerRef}
			{...innerProps}
			className={clsx(
				styles.option,
				isSelected && styles.active,
				mod.inherit && styles.inherit,
				mod.simple && styles.simple
			)}
		>
			{!mod.simple && <span className={styles.check} />}

			{props.data.icon && (
				<img
					className={styles.icon}
					src={props.data.icon}
					alt={props.data.label}
				/>
			)}
			{props.data.prev && <span>{props.data.prev}</span>}

			<div className={styles.name}>{props.data.label} </div>
		</div>
	)
}

export const ReactSelect: FC<IPropsSelect> = ({
	selectRef,
	value,
	onChange,
	options,
	connect,
	defaultValue,
	name,
	isDisabled,
	containerClassName,
	registerConfig,
	isSearchable,
	modifier,
	placeholder,
	isCode
}) => {
	const SelectComponent = connect ? ConnectSelect : Select
	const mod = {
		inherit: false,
		isHigh: false,
		simple: false
	}

	modifier?.forEach(item => {
		mod[item] = true
	})

	return (
		<div
			className={clsx(
				styles.wrapper,
				mod.simple && styles.simple,
				mod.isHigh && styles.isHigh,
				isCode && styles.code,
				isDisabled && styles.disabled,
				containerClassName
			)}
		>
			<SelectComponent
				styles={customStyles(mod)}
				value={value}
				isDisabled={isDisabled}
				defaultValue={defaultValue}
				options={options}
				
				ref={selectRef}
				classNamePrefix={'react-select'}
				onChange={onChange}
				noOptionsMessage={() => getMessageLocality('not_found')}
				name={name}
				placeholder={placeholder}
				isSearchable={isSearchable}
				components={{
					IndicatorSeparator: () => null,
					Option: props => <MyOption {...props} mod={mod} />
				}}
				{...(connect
					? {
							registerConfig
					  }
					: {})}
			/>
		</div>
	)
}
