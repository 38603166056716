import { FC } from 'react'
import { useSearchParams } from 'react-router-dom'

import styles from './Tabs.module.scss'
import { tabsData } from './tabs.data'
import { ITabs, TypeTabNames } from './tabs.interface'
import TabButton from '@/entities/profile/tab-button'

const Tabs: FC<ITabs> = ({ currentTab, setCurrentTab }) => {
	const [, setSearchParams] = useSearchParams()

	const onClickTabButton = (tabName: TypeTabNames) => {
		if (setCurrentTab) return setCurrentTab(tabName)
		setSearchParams({ tab: tabName })
	}
	return (
		<div className={styles.tabs}>
			{tabsData.map(tab => (
				<TabButton
					key={tab.name}
					label={tab.name}
					isActive={tab.name === currentTab}
					onClick={() => onClickTabButton(tab.name)}
				/>
			))}
		</div>
	)
}

export default Tabs
