import { FC, useEffect } from 'react'
import { FieldValues, UseFormReturn, useForm } from 'react-hook-form'

import { useChangePassword } from '../api'

import styles from './ChangePasswordFields.module.scss'
import { messages } from '@/i18n/messages'
import { useIsMobile } from '@/shared/hooks/useIsMobile'
import { useAppSelector } from '@/shared/model'
import { Button, FormWrapper, Input } from '@/shared/ui/form'
import { getMessageLocality } from '@/shared/utils'

interface IProps {
	onSubmitData: (data: FieldValues) => void
	methodsData: UseFormReturn<FieldValues, any, undefined>
}

const ChangePasswordFields: FC<IProps> = ({ onSubmitData, methodsData }) => {
	const { changePassword } = useChangePassword()
	const { isMobile } = useIsMobile()

	const { locale } = useAppSelector(state => state.defaultReducer)
	const methods = useForm({
		mode: 'onBlur'
	})
	const {
		formState: { errors }
	} = methods

	const onSubmit = async (data: FieldValues) => {
		try {
			if (data?.old_password) {
				//@ts-ignore
				await changePassword(data)
			}

			if (isMobile) onSubmitData(methodsData.getValues())
		} catch (error) {}
		methods.reset({ old_password: '', new_password: '', repeat_password: '' })
	}

	const passwordValue =
		methods.watch('old_password') ||
		methods.watch('new_password') ||
		methods.watch('repeat_password')

	useEffect(() => {
		if (!passwordValue) {
			setTimeout(() => {
				methods.clearErrors(['old_password', 'new_password', 'repeat_password'])
			}, 10)
		}
	}, [passwordValue])

	return (
		<FormWrapper
			methods={methods}
			onSubmit={onSubmit}
			className={styles.wrapper}
		>
			<div className={styles.title}>
				{getMessageLocality('change_password')}
			</div>
			<div className={styles.fields}>
				<Input
					name='old_password'
					connect
					type='password'
					isPassword
					className={styles.field}
					errorText={errors.old_password?.message}
					placeholder={messages[locale].old_password}
					registerConfig={
						passwordValue || !isMobile
							? {
									required: messages[locale].fill_field,
									minLength: {
										value: 8,
										message: messages[locale].minimum_characters + ' - 8'
									}
							  }
							: undefined
					}
				/>

				<Input
					name='new_password'
					connect
					type='password'
					isPassword
					className={styles.field}
					errorText={errors.new_password?.message}
					placeholder={messages[locale].new_password}
					registerConfig={
						passwordValue || !isMobile
							? {
									required: messages[locale].fill_field,
									minLength: {
										value: 8,
										message: messages[locale].minimum_characters + ' - 8'
									}
							  }
							: undefined
					}
				/>

				<Input
					name='repeat_password'
					connect
					type='password'
					isPassword
					className={styles.field}
					errorText={errors.repeat_password?.message}
					placeholder={messages[locale].repeat_password}
					registerConfig={
						passwordValue || !isMobile
							? {
									required: messages[locale].fill_field,
									minLength: {
										value: 8,
										message: messages[locale].minimum_characters + ' - 8'
									},
									validate: value =>
										value === methods.getValues().new_password ||
										messages[locale].passwords_dont_match
							  }
							: undefined
					}
				/>

				<Button
					classNameContainer={styles.button}
					title={getMessageLocality(isMobile ? 'save' : 'save_password')}
					modifiers={['gold', 'small']}
				/>
			</div>
		</FormWrapper>
	)
}

export default ChangePasswordFields
